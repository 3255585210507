import styled from 'styled-components/macro';

export const SFooterDisclaimer = styled.div`
  margin-top: auto;
  @media screen and (min-width: 768px) {
    max-width: 24.0625rem;
  }
`;
export const SFooterDisclaimerDescription = styled.p`
  color: rgba(255, 255, 255, 0.19);
  font-size: 12px;
  line-height: 22px;
  font-weight: 400;
  @media screen and (min-width: 768px) {
    max-width: 24.0625rem;
    font-size: 14px;
  }
`;

export const SFooterDisclaimerText = styled.p`
  margin: 0 0 10px;
  color: white;
  font-size: 12px;
  line-height: 22px;
  font-weight: 400;
  @media screen and (min-width: 768px) {
    font-size: 14px;
  }
`;

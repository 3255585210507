import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';

import { Colors, Fonts } from '../styles/theme';
import { Box, IBoxProps } from './Box';

interface IProps extends IBoxProps {
  type: Fonts;
  className?: string;
  color?: Colors;
  align?: 'center' | 'left' | 'right';
  maxWidth?: string;
  whiteSpace?: string;
  decoration?: string;
  onClick?: MouseEventHandler;
}

export const Typography: React.FC<IProps> = props => {
  const {
    type,
    color,
    className,
    children,
    align,
    whiteSpace,
    onClick,
    maxWidth,
    decoration,
    boxAs,
    ...boxProps
  } = props;

  return (
    <Styled
      type={type}
      className={className}
      boxAs={boxAs || 'p'}
      onClick={onClick}
      color={color}
      align={align}
      whiteSpace={whiteSpace}
      maxWidth={maxWidth}
      decoration={decoration}
      {...boxProps}
    >
      {children}
    </Styled>
  );
};

interface IStyledProps {
  align?: IProps['align'];
  type: IProps['type'];
  color?: IProps['color'];
  whiteSpace?: IProps['whiteSpace'];
  maxWidth?: IProps['maxWidth'];
  width?: IProps['width'];
  decoration?: IProps['decoration'];
}

const Styled = styled(Box)<IStyledProps>`
  ${({ theme, type, color, align, whiteSpace, maxWidth, width, decoration }) => `
    font-size: ${theme.fonts[type].fontSize};
    font-weight: ${theme.fonts[type].fontWeight};
    line-height: ${theme.fonts[type].lineHeight};

    @media ${theme.breakpoints.tablet} {
      ${
        theme.fontsMobile[type] &&
        `
      font-size: ${theme.fontsMobile[type].fontSize};
      font-weight: ${theme.fontsMobile[type].fontWeight};
      line-height: ${theme.fontsMobile[type].lineHeight};`
      }
    }
    
    text-transform: ${theme.fonts[type].upperCase ? 'uppercase' : 'normal'};
    color: ${theme.colors[color || 'white']};
    font-family: ${theme.fonts[type].fontFamily || 'Montserrat'};
    text-align: ${align || 'left'};
    white-space: ${whiteSpace || 'normal'};
    width: ${width || '100%'};
    display: inline-block;
    max-width: ${maxWidth || 'unset'};
    text-decoration: ${decoration || 'none'};
  `}
`;

type NavigationType = 'main';
type LinkType = 'text';

export interface IDropdownLink {
  id: number;
  link_type: LinkType;
  link_text: string;
  url: string;
  isExternal?: boolean;
  image?: string;
}

interface ILink extends IDropdownLink {
  navigation_type: NavigationType;
  is_dropdown: boolean;
  items?: Array<IDropdownLink>;
  order_number?: number;
  exclude_locations?: Array<string>;
}

export const headerLinks: Array<ILink> = [
  {
    id: 1,
    navigation_type: 'main',
    is_dropdown: true,
    isExternal: true,
    link_type: 'text',
    link_text: 'About',
    url: '',
    order_number: 6,
    items: [
      {
        id: 1,
        link_type: 'text',
        link_text: 'About the game',
        isExternal: true,
        url: 'https://wizardia.io/about-the-game',
      },
      {
        id: 2,
        link_type: 'text',
        link_text: 'Tournaments',
        isExternal: true,
        url: 'https://wizardia.io/tournaments',
      },
      {
        id: 3,
        link_type: 'text',
        link_text: 'Winners',
        isExternal: true,
        url: 'https://wizardia.io/tournaments/winners',
      },
    ],
  },
  {
    id: 3,
    navigation_type: 'main',
    is_dropdown: true,
    link_type: 'text',
    link_text: 'Buy NFTs',
    url: '',
    order_number: 6,
    isExternal: true,
    items: [
      {
        id: 1,
        link_type: 'text',
        link_text: 'Arena NFTs',
        isExternal: true,
        url: 'https://wizardia.io/nft-sale',
      },
      {
        id: 2,
        link_type: 'text',
        link_text: 'Market NFTs',
        isExternal: true,
        url: 'https://wizardia.io/market-nft',
      },
      {
        id: 3,
        link_type: 'text',
        link_text: 'Wizard NFTs',
        isExternal: true,
        url: 'https://wizardia.io/collection',
      },
      {
        id: 4,
        link_type: 'text',
        link_text: 'Mystery Boxes',
        isExternal: true,
        url: 'https://wizardia.io/mystery-boxes',
      },
      {
        id: 5,
        link_type: 'text',
        link_text: 'Consumables',
        isExternal: true,
        url: 'https://wizardia.io/consumables',
      },
    ],
  },
  {
    id: 4,
    navigation_type: 'main',
    is_dropdown: true,
    link_type: 'text',
    link_text: '$WZRD Token',
    url: '',
    order_number: 6,
    isExternal: true,
    items: [
      {
        id: 1,
        link_type: 'text',
        link_text: 'Staking',
        isExternal: true,
        url: 'https://staking.wizardia.io/',
      },
      {
        id: 2,
        link_type: 'text',
        link_text: 'Tokenomics',
        isExternal: true,
        url: 'https://wizardia.io/tokenomics',
      },
      {
        id: 3,
        link_type: 'text',
        link_text: 'Buy on CEX',
        isExternal: true,
        url: 'https://www.gate.io/cn/trade/WZRD_USDT',
      },
    ],
  },
  {
    id: 5,
    navigation_type: 'main',
    is_dropdown: true,
    link_type: 'text',
    link_text: 'Community',
    url: '',
    order_number: 6,
    isExternal: true,
    items: [
      {
        id: 1,
        link_type: 'text',
        link_text: 'Discord',
        isExternal: true,
        url: 'https://discord.gg/wizardia',
        image: 'discord',
      },
      {
        id: 2,
        link_type: 'text',
        link_text: 'Telegram',
        isExternal: true,
        url: 'https://t.me/Wizardia',
        image: 'telegram',
      },
      {
        id: 3,
        link_type: 'text',
        link_text: 'Twitter',
        isExternal: true,
        url: 'https://twitter.com/PlayWizardia',
        image: 'twitter',
      },
      {
        id: 4,
        link_type: 'text',
        link_text: 'Youtube',
        isExternal: true,
        url: 'https://www.youtube.com/c/Wizardia',
        image: 'youtube',
      },
    ],
  },
  {
    id: 6,
    navigation_type: 'main',
    is_dropdown: false,
    link_type: 'text',
    link_text: 'Blog',
    url: 'https://wizardia.io/blog',
    order_number: 7,
  },
];

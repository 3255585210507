import React from 'react';
import styled from 'styled-components';

interface ISection {
  background?: string;
  margin?: string;
}

export const Section: React.FC<ISection> = ({ children, ...rest }) => (
  <SectionWrapper {...rest}>{children}</SectionWrapper>
);

const SectionWrapper = styled.section<ISection>`
  background: ${p => p.background || 'transparent'};
  margin: ${p => p.margin || undefined};
`;

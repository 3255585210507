import { useEffect, useState } from 'react';

const useHostname = (env = '') => {
  const [hostname, setHostname] = useState(process.env.REACT_APP_STAGING_API);

  useEffect(() => {
    switch (env) {
      case 'production':
        setHostname(process.env.REACT_APP_PROD_API);
        break;
      default:
        setHostname(process.env.REACT_APP_STAGING_API);
    }
  }, [env]);

  return hostname;
};

export default useHostname;

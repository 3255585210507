const SvgComponent = () => (
  <svg
    width={17}
    height={14}
    viewBox='0 0 17 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M17 0.00784565C16.26 0.534866 15.4407 0.937952 14.5736 1.20158C14.1083 0.661287 13.4898 0.278341 12.8018 0.104535C12.1138 -0.0692709 11.3896 -0.0255511 10.7271 0.229782C10.0645 0.485114 9.49561 0.93974 9.0973 1.53217C8.69899 2.1246 8.49049 2.82626 8.5 3.54224V4.32246C7.14203 4.35801 5.79644 4.05391 4.58305 3.43726C3.36967 2.8206 2.32615 1.91052 1.54545 0.788065C1.54545 0.788065 -1.54545 7.81003 5.40909 10.9309C3.81768 12.0216 1.92189 12.5685 0 12.4913C6.95455 16.3924 15.4545 12.4913 15.4545 3.51883C15.4538 3.3015 15.4331 3.08471 15.3927 2.87125C16.1814 2.08595 16.7379 1.09447 17 0.00784565Z'
      fill='white'
    />
  </svg>
);

export default SvgComponent;

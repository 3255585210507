import { useState } from 'react';

import {
  StyledCollapsableContainer,
  StyledCollapsableContainerContent,
  StyledCollapsableContainerTitle,
} from './HeaderLinksCollapsableContainer.styles';

export interface ICollapsableContainerProps {
  children: React.ReactNode;
  title: React.ReactNode;
  minWidth?: number;
  titleHeight?: number;
  noBorder?: boolean;
  p?: string;
  arrowSize?: string;
  width?: string;
  borderRadius?: string;
}

const HeaderLinksCollapsableContainer: React.FC<ICollapsableContainerProps> = ({
  children,
  title,
  ...rest
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const toggleExpanded = () => {
    setExpanded(prevState => !prevState);
  };
  return (
    <StyledCollapsableContainer
      tabIndex={0}
      onClick={toggleExpanded}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          return toggleExpanded();
        }
      }}
      expanded={expanded}
      {...rest}
    >
      <>
        <StyledCollapsableContainerTitle expanded={expanded} {...rest}>
          {title}
        </StyledCollapsableContainerTitle>
        <StyledCollapsableContainerContent expanded={expanded}>
          {children}
        </StyledCollapsableContainerContent>
      </>
    </StyledCollapsableContainer>
  );
};

export default HeaderLinksCollapsableContainer;

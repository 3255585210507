import React from 'react';

interface IPictureProps {
  srcSet: {
    origin: string;
    1920?: string;
    1280?: string;
    800?: string;
    480?: string;
  };
  retina?: boolean;
  className?: string;
}

export const Picture: React.FC<IPictureProps> = ({
  srcSet,
  retina,
  children,
  className,
}) => {
  //
  const srcset = Object.keys(srcSet).map((key, i) => {
    let item = srcSet[key as keyof typeof srcSet];
    if (retina) {
      return key === 'origin' ? item : `${item} ${key}x`;
    } else {
      return key === 'origin' ? item : `${item} ${key}w`;
    }
  });
  return (
    <picture className={className}>
      <source type='image/webp' srcSet={srcset.join(',')}></source>
      {children}
    </picture>
  );
};

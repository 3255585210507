const SvgComponent = () => (
  <svg
    width={19}
    height={19}
    viewBox='0 0 19 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M16.095 1.256A15.258 15.258 0 0 0 12.175 0c-.169.311-.366.73-.502 1.063a14.115 14.115 0 0 0-4.344 0A11.458 11.458 0 0 0 6.82 0C5.444.243 4.128.67 2.898 1.26.417 5.082-.256 8.81.08 12.485a15.561 15.561 0 0 0 4.807 2.512c.387-.543.732-1.12 1.03-1.729a10.04 10.04 0 0 1-1.622-.805c.136-.103.27-.21.398-.32 3.126 1.49 6.523 1.49 9.612 0 .13.11.263.217.398.32-.515.316-1.059.587-1.625.807.297.607.641 1.186 1.03 1.729a15.526 15.526 0 0 0 4.81-2.514c.395-4.26-.674-7.955-2.824-11.23Zm-9.751 8.97c-.939 0-1.708-.894-1.708-1.982s.753-1.983 1.708-1.983 1.724.893 1.708 1.983c.001 1.088-.753 1.982-1.708 1.982Zm6.312 0c-.938 0-1.708-.894-1.708-1.982s.753-1.983 1.708-1.983 1.725.893 1.708 1.983c0 1.088-.753 1.982-1.708 1.982Z'
      fill='#fff'
    />
  </svg>
);

export default SvgComponent;

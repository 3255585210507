import React from 'react';
import { Routes } from 'routes';

// import "react-toastify/dist/ReactToastify.css";

interface IApp {
  props: any;
}

export const App: React.FC<IApp> = ({ props }) => {
  sessionStorage.setItem('env', props?.env);
  return (
    <div>
      <Routes />
    </div>
  );
};

/* eslint-disable @typescript-eslint/naming-convention */
import { ReactNode, useRef, useState } from 'react';

import { useOutsideAlerter } from 'hooks/use-visible';

import {
  DropdownWrapper,
  LabelText,
  LabelWrapper,
  MoreMenu,
  StyledLink,
  Wrapper,
} from './Dropdown.styles';

type LinkType = 'text';

export interface IDropdownLink {
  id: number;
  link_type: LinkType;
  link_text: string;
  url: string;
  isExternal?: boolean;
  image?: string;
}

export interface DropdownProps {
  defaultLabel?: ReactNode | string;
  label: ReactNode;
  items: Array<ReactNode> | ReactNode;
  item?: IDropdownLink;
  labelUrl?: string;
  p?: string;
  itemPadding?: string;
  arrowSize?: string;
  m?: string;
  addClass?: string;
  moreMenuMinWidth?: string;
  moreMenuWidth?: string;
}

const Dropdown: React.FC<DropdownProps> = ({ label, items, labelUrl }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<any>(null);
  const { isComponentVisible } = useOutsideAlerter(ref);

  const handleMenuClick = () => {
    if (!isComponentVisible) {
      if (open) setOpen(false);
    } else {
      setOpen(prev => !prev);
    }
  };

  return (
    <DropdownWrapper onMouseLeave={() => setOpen(false)} onClick={handleMenuClick}>
      <Wrapper onMouseEnter={() => setOpen(true)} open={open}>
        <LabelWrapper>
          {!labelUrl && (
            <LabelText onClick={() => setOpen(prev => !prev)}>{label}</LabelText>
          )}
          {!!labelUrl && (
            <StyledLink to={labelUrl}>
              <LabelText>{label}</LabelText>
            </StyledLink>
          )}
        </LabelWrapper>
        <MoreMenu ref={ref} open={open}>
          {items}
        </MoreMenu>
      </Wrapper>
    </DropdownWrapper>
  );
};

export default Dropdown;

import { useEffect, useState } from 'react';

export const useOutsideAlerter = (ref: any) => {
  const [isComponentVisible, setIsComponentVisible] = useState(false);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsComponentVisible(false);
      } else {
        setIsComponentVisible(true);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return { isComponentVisible, setIsComponentVisible };
};

import React from 'react';
import styled from 'styled-components';

import { Typography } from 'components';

interface IPrizeBlock {
  prizeAmount: string;
  currency: string;
}

export const PrizeBlock: React.FC<IPrizeBlock> = ({ currency, prizeAmount }) => (
  <PrizeBlockWrapper>
    <Title>Your prize</Title>
    <PrizeText>{prizeAmount}</PrizeText>
    <Typography px={0.5} align='center' color='primary' type='p1'>
      {currency}
    </Typography>
  </PrizeBlockWrapper>
);

const PrizeText = styled.p`
  font-family: 'Equinox';
  font-style: normal;
  font-weight: 400;
  font-size: 100px;
  line-height: 120%;
  text-align: center;
  text-transform: uppercase;
  color: ${p => p.theme.colors.primary};

  text-shadow: 0px 0px 0.625rem ${p => p.theme.colors.primary}; ;
`;

const PrizeBlockWrapper = styled.fieldset`
  max-width: 14rem;
  margin: auto;
  position: relative;
  padding: 1.25rem 0;
  border: 1px solid #7aebe466;
  box-sizing: border-box;
  backdrop-filter: blur(30px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 40px;
`;

const Title = styled.legend`
color ${p => p.theme.colors.primary};
padding: 0 1rem;
font-size: 1.25rem;
`;

import { Box, Typography } from 'components';

import { ITokenInterface } from 'types/services/token';
import styled from 'styled-components';

export const TokenErrorModalContent: React.FC<ITokenInterface> = ({
  isValid,
  errorMessage,
}) => {
  return (
    <StyledBox>
      <Typography mt={1.25} maxWidth='27.563rem' align='center' type='p1'>
        {errorMessage}
      </Typography>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  padding: 1.188rem 1.875rem;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  // background: rgba(255, 255, 255, 0.2);
  // max-width: fit-content;
  // margin: 2.5rem auto 0;
  // border-radius: 2.5rem;
`;

import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'slices/user/selectors';
import { setEmail as setEmailAction } from 'slices/user/userSlice';

export const useUser = () => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const setEmail = (email: string) => {
    dispatch(setEmailAction({ email }));
  };

  return {
    setEmail,
    user,
  };
};

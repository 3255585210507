export class StorageService {
  public static getAccessToken() {
    return localStorage.getItem('wizardiaGiveawayAccessToken');
  }

  public static setAccessToken(token: string) {
    return localStorage.setItem('wizardiaGiveawayAccessToken', token);
  }

  public static clearTokens() {
    localStorage.removeItem('wizardiaGiveawayAccessToken');
  }
}

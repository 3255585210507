/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { Icon } from 'assets/Icon';
import { handleNavigate } from 'layouts/components/header/HeaderListItem';
import styled from 'styled-components';

import { Box, FlexWrapper, Typography } from 'components';
import { PrimaryButton } from 'components/buttons/PrimaryButton';
import { Image } from 'components/other/Image';
import useHostname from 'hooks/use-pathname';
import { IGiveaway } from 'services/api/airdrops';
// import { apiUrl } from 'services/baseQuery';

export const WinnerCard: React.FC<IGiveaway> = ({
  prize_pool,
  title,
  link,
  image_src,
  time,
}) => (
  <CardWrapper onClick={() => handleNavigate(link)}>
    <Image width='100%' alt='test' src={`${useHostname()}/${image_src}`} />
    <StyledBox />
    <FlexWrapper
      height='100%'
      direction='column'
      justifyContent='flex-end'
      pb={1.25}
      px={1.25}
    >
      <TextWrapper width='100%' px={1.25}>
        <Title mb={1.25} align='center' type='h4'>
          {title}
        </Title>
        <StyledFlexWrapper justifyContent='center' alignItems='center'>
          <Icon name='clock' />
          <EndDate width='fit-content' align='center' type='p2'>
            {time}
          </EndDate>
        </StyledFlexWrapper>
        <Typography mt={1.25} align='center' type='p2'>
          {prize_pool}
        </Typography>
      </TextWrapper>
      <ButtonWrapper width='100%'>
        <PrimaryButton
          type='button-regular'
          maxWidth='100%'
          width='100%'
          height='3.75rem'
        >
          View winners
        </PrimaryButton>
      </ButtonWrapper>
    </FlexWrapper>
  </CardWrapper>
);

const StyledBox = styled(Box)`
border-radius: 36px;
height: 0.625rem;
}
`;

const StyledFlexWrapper = styled(FlexWrapper)`
&:before,
&:after {
 background-color: #000;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
  background-image: linear-gradient(90deg,hsla(0,0%,100%,1) 10%,hsla(0,0%,100%,0) 0);
  background-position: top;
  background-size: 10px 2px;
  background-repeat: repeat-x;
}

&:before {
  right: 1rem;
  margin-left: -50%;
}

&:after {
  left: 1rem;
  margin-right: -50%;
}
}
`;

const ButtonWrapper = styled(Box)`
  opacity: 1;
  margin-top: 1.875rem;
  overflow: hidden;
  transition: all 0.25s;
  transform: translateY(6.25rem);
  ${p => `
  @media ${p.theme.breakpoints.mobile} {
      transform: none;
    }
  `}
  ${p => `
  @media ${p.theme.breakpoints.tablet} {
      margin-top: auto;
    }
  `}
`;
const Title = styled(Typography)`
  font-size: 1.125rem;
`;

const TextWrapper = styled(Box)`
  transition: all 0.25s;
  overflow: hidden;
  ${p => `
  @media ${p.theme.breakpoints.tablet} {
    margin-top: -5.5rem;
    }
  `}
`;

const CardWrapper = styled(Box)`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    rgba(14, 15, 16, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(10px);
  border-radius: 2.5rem;
  ${TextWrapper} {
    transform: translateY(-30px);
  }
  @media (min-width: 767px) {
    ${TextWrapper} {
      transform: translateY(0);
    }
    :hover {
      ${TextWrapper} {
        transform: translateY(-30px);
      }
      ${ButtonWrapper} {
        opacity: 1;
        transform: translateY(0);
      }
      border-color: ${p => p.theme.colors.primary};
    }
  }
`;

const EndDate = styled(Typography)`
  color: ${p => p.theme.colors.primary};
  margin-left: 0.375rem;
`;

/* eslint-disable @typescript-eslint/naming-convention */
import {
  SFooterColumn,
  SFooterColumnLabel,
  SFooterColumnList,
  SFooterLink,
  SFooterText,
} from './FooterColumn.styles';

interface FooterLinkProps {
  icon_path: null;
  id: number;
  link_text: string;
  link_type: string;
  navigation_type: string;
  order_number: number;
  url: string;
}

export interface FooterColumn {
  label: string;
  items: Array<FooterLinkProps>;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const FooterColumn: React.FC<FooterColumn> = ({ label, items }) => {
  const sortedItems = items.sort((a, b) => a.order_number - b.order_number);

  return (
    <SFooterColumn>
      <SFooterColumnLabel>{label}</SFooterColumnLabel>
      <SFooterColumnList className='m-primary'>
        {sortedItems?.map((item, index) => (
          <li key={index}>
            <SFooterLink href={item.url} target='_blank' rel='noreferrer'>
              <SFooterText>{item.link_text}</SFooterText>
            </SFooterLink>
          </li>
        ))}
      </SFooterColumnList>
    </SFooterColumn>
  );
};

export default FooterColumn;

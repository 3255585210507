import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

export const Wrapper = styled('div')<{ open: boolean }>`
  cursor: pointer;
  z-index: 2;
  display: inline-block;
  max-width: max-content;
  font-size: 14px;
  position: relative;
  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: ${props => (props.open ? '160px' : 0)};
    right: 0;
    bottom: calc(100% - 80px);
    z-index: -1;
    ._left & {
      right: auto;
      left: 16px;
    }
  }
`;
export const LabelWrapper = styled('div')`
  display: flex;
  column-gap: 8px;
  align-items: center;
  &:hover {
    &::after {
      background: linear-gradient(45deg, rgb(122, 235, 228, 0.3) 0%, transparent 65%);
      border-color: #7aebe4;
    }
  }
  &::after {
    content: '';
    width: 6px;
    height: 6px;
    transform: rotate(-45deg);
    background: linear-gradient(45deg, rgb(255, 255, 255, 0.3) 0%, transparent 65%);
    position: relative;
    top: -2px;
    right: 0;
    border: solid 0 white;
    border-width: 0 0 1px 1px;
  }
  & > * {
    transition: all 0.2s ease;
  }
  &:hover {
    & > * {
      color: #7aebe4;
    }
  }
`;

export const MoreMenu = styled('div')<{ open: boolean }>`
  flex-direction: column;
  opacity: ${props => (props.open ? '1' : '0')};
  pointer-events: ${props => (props.open ? 'all' : 'none')};
  visibility: ${props => (props.open ? 'visible' : 'hidden')};
  display: flex;
  max-height: ${props => (props.open ? 'unset' : 0)};
  position: absolute;
  top: calc(100% + 20px);
  padding: 20px;
  backdrop-filter: blur(40px);
  width: auto;
  min-width: 130px;
  right: 0;
  border: 1px solid #7aebe4;
  border-radius: 30px;
  border-top-right-radius: 10px;
  z-index: 5;
  text-align: left;
  transition: opacity 0.2s ease;
  > * {
    padding: 5px 0;
    white-space: nowrap;
  }

  @media screen and (min-width: 480px) {
    width: 230px;
    > * {
      white-space: normal;
    }
  }
  ._left & {
    right: auto;
    left: 16px;
  }
  ._wide & {
    min-width: 256px;
  }
  ._light & {
    background: white;
    border-color: greenyellow;
    backdrop-filter: unset;
    > * {
      color: black;
    }
    > *:not(a) {
      padding: 0;
    }
  }
`;

export const DropdownWrapper = styled.div`
  position: relative;
`;

export const LabelText = styled.p`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: white;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

import React from 'react';
import { Icon } from 'assets/Icon';

import bgJpg from '../../../../assets/images/bg/bg-menu.jpg';
import bgWebp from '../../../../assets/images/bg/bg-menu.webp';
import { headerLinks } from '../data';
import { HeaderLinks } from '../HeaderLinks/HeaderLinksUpdated';
import {
  LinksWrapper,
  MobileMenuWrapper,
  SocialIconLink,
  SocialIconsWrapper,
  StyledPicture,
} from './MobileMenuUpdated.styles';

interface IMobileMenu {
  open: boolean;
  onLinkClick: () => void;
}

export const MobileMenu: React.FC<IMobileMenu> = ({ open, onLinkClick }) => {
  const socialObj = headerLinks.find(item => item.link_text === 'Community');

  return (
    <>
      <StyledPicture open={open} srcSet={{ origin: bgWebp, 1920: bgWebp }}>
        <img src={bgJpg} alt='background' />
      </StyledPicture>
      <MobileMenuWrapper open={open}>
        <LinksWrapper>
          <HeaderLinks onLinkClick={onLinkClick} />
        </LinksWrapper>
        <SocialIconsWrapper>
          {socialObj &&
            socialObj.items &&
            socialObj.items.map(item => (
              <SocialIconLink
                key={item.id}
                href={item.url}
                target='_blank'
                rel='noreferrer'
              >
                {item?.image && <Icon name={item.image} />}
              </SocialIconLink>
            ))}
        </SocialIconsWrapper>
      </MobileMenuWrapper>
    </>
  );
};

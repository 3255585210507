/* eslint-disable camelcase */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { IDrop } from 'pages/mydrops/elements/DropList';
import { apiUrl } from 'services/baseQuery';

interface IValidateToken {
  token: string;
}

interface IValidateBep {
  email: string;
  bep20_address: string;
  token: string;
  giveaway_id: number;
}

// TODO: need to move to constants
export const tokenApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: apiUrl() }),
  reducerPath: 'tokenApi',
  endpoints: builder => ({
    validateToken: builder.mutation<
      { token_valid: boolean; airdrops: Array<IDrop>; email: string; message?: string },
      IValidateToken
    >({
      query: body => ({
        url: '/api/validate-token',
        method: 'POST',
        body,
      }),
    }),
    validateBep: builder.mutation<
      { error: boolean | null; message: string | null },
      IValidateBep
    >({
      query: body => ({
        url: '/api/store-address',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useValidateTokenMutation, useValidateBepMutation } = tokenApi;

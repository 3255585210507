import styled from 'styled-components/macro';

export const StyledCollapsableContainer = styled.div<{
  expanded: boolean;
}>`
  cursor: pointer;
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  min-height: max-content;
  overflow: hidden;
  transition: all 0.2s ease;
  padding: 0 4px 0 0;

  @media screen and (min-width: 480px) {
    border-radius: 20px;
  }

  @media screen and (min-width: 1024px) {
    max-height: 48px;
  }
`;

export const StyledCollapsableContainerTitle = styled.div<{
  expanded: boolean;
}>`
  display: flex;
  width: 100%;
  position: relative;
  user-select: none;
  padding-right: 24px;

  &::after {
    content: '';
    width: 6px;
    height: 6px;
    transform-origin: 50% 50%;
    transform: ${props => (props.expanded ? 'rotate(135deg)' : 'rotate(-45deg)')};
    position: absolute;
    background: linear-gradient(45deg, rgb(255, 255, 255, 0.3) 0%, transparent 65%);
    top: 6px;
    right: 6px;
    border: solid 0 white;
    border-width: 0 0 1px 1px;
    transition: all 0.2s ease;
  }
`;

export const StyledCollapsableContainerContent = styled.div<{
  expanded: boolean;
}>`
  opacity: ${props => (props.expanded ? 1 : 0)};
  display: flex;
  flex-direction: column;
  margin-top: ${props => (props.expanded ? '16px' : '0')};
  max-height: ${props => (props.expanded ? '480px' : '0')};
  overflow: hidden;
  transition: all 0.4s ease;
  font-size: 14px;

  @media screen and (min-width: 480px) {
    font-size: 16px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 18px;
  }
`;

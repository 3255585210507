import React from 'react';
import styled, { css } from 'styled-components';

import { FlexWrapper } from 'components';

export enum InputTypeEnum {
  Text = 'text',
  Telephone = 'tel',
  Email = 'email',
  Number = 'number',
}

// enum AutoComplete {
//   Off = 'none',
//   On = 'on',
// }

export interface IInputDefaultProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: InputTypeEnum;
  label?: string;
  className?: string;
  min?: number;
  max?: number;
  value?: string | number;
  pattern?: string;
  maxLength?: number;
  autoComplete?: string | undefined;
  required?: boolean;
  error?: boolean;
  onKeyPress?: (e: React.KeyboardEvent) => void;
}

export const Input: React.FC<IInputDefaultProps> = ({
  placeholder = '',
  type = 'text',
  name,
  label,
  className,
  min = 1,
  max,
  value,
  onChange,
  onKeyPress,
  pattern,
  maxLength,
  error,
  // autoComplete = false,
  required = true,
}) => {
  const checkEmail = () => {
    if (type === 'email') {
      return (e: React.ChangeEvent<HTMLInputElement>) =>
        e.target.setCustomValidity('Please enter email adress');
    }
    return () => null;
  };

  const validateInput = () => {
    if (type === 'email') {
      return (e: React.ChangeEvent<HTMLInputElement>) => e.target.setCustomValidity('');
    }
    return () => null;
  };

  const validateEmail = () => {
    if (type === 'email') {
      return '[a-zA-Z0-9.+-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}';
    }
    return pattern;
  };

  return (
    <InputComponent error={error} hasInput={!!value} className={className}>
      <Field
        autoComplete='off'
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        onInvalid={checkEmail}
        onInput={validateInput}
        type={type}
        name={name}
        min={min}
        max={max}
        maxLength={maxLength}
        placeholder={placeholder}
        pattern={pattern || validateEmail()}
        required={required}
      />
      <LabelContainer pl={1} justifyContent='flex-start' alignItems='center'>
        <Label error={error} hasInput={!!value} htmlFor={name}>
          {label}
        </Label>
      </LabelContainer>
    </InputComponent>
  );
};

const LabelContainer = styled(FlexWrapper)`
  width: 100%;
  position: absolute;
  top: -0.4rem;
  left: 0.5rem;
`;

const Label = styled.label<{ hasInput?: boolean; error?: boolean }>`
  pointer-events: none;
  transform: ${props =>
    props.hasInput
      ? 'translate(0, -0.512rem) scale(0.8)'
      : 'translate(0, 1.5rem) scale(1)'};
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: rgba(255, 255, 255, 0.5);
  line-height: 1;
  ${({ theme }) => theme.fonts['body-s-regular']}

  ${({ hasInput, error }) =>
    hasInput &&
    css`
      transform: translate(0, 1rem) scale(0.8);
      font-size: 0.75rem;
      color: ${({ theme }) =>
        error ? theme.colors['secondary-red'] : theme.colors['primary-button']};
      top: -0.125rem;
      ${({ theme }) => theme.fonts['label-s-regular']}
    `}
  ${p => `
    @media ${p.theme.breakpoints.mobile} {
        font-size: 0.875rem;
    }
  `};
`;

const InputComponent = styled(FlexWrapper)<{ hasInput?: boolean; error?: boolean }>`
  padding: 0 1.188rem;
  position: relative;
  min-width: 100%;
  max-height: 3.5rem;
  background: rgba(14, 15, 16, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  backdrop-filter: blur(10px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 40px;
  ${({ theme }) => theme.fonts['body-s-regular']}

  border: 1px solid
      ${({ theme, error }) => (error ? theme.colors['secondary-red'] : 'none')};

  :focus-within {
    ${Label} {
      font-size: 0.75rem;
      transform: translate(0, 1rem) scale(0.8);
      top: -0.5rem;
    }
  }
`;

const Field = styled.input`
  padding: 1.188rem;
  width: 100%;
  background: transparent;
  margin-top: ${({ theme }) => theme.spacing['0.25']};
  border: none;
  color: white !important;
  outline: none;
  height: 100%;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  text-align: left;
  padding-left: 0.3rem;
  font-size: 0.875rem;
  font-family: 'Montserrat';

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s;
  }
`;

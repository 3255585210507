import styled from 'styled-components/macro';

export const SFooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  max-width: 11.5625rem;
`;

export const SFooterColumnList = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
`;

export const SFooterColumnLabel = styled.span`
  display: inline-block;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.2px;
  color: white;
  margin: 0 0 20px;

  @media screen and (min-width: 768px) {
    font-size: 18px;
  }
`;

export const SFooterLink = styled.a`
  padding: 0.625rem 0;
  text-decoration: none;
`;

export const SFooterText = styled.p`
  cursor: pointer;
  color: white;
  font-size: 12px;
  line-height: 22px;
  font-weight: 400;
  &:hover {
    color: #7aebe4;
  }
  @media screen and (min-width: 768px) {
    font-size: 14px;
  }
`;

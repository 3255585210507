/* eslint-disable camelcase */
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { API_URLS } from 'config/api';
import queryString from 'query-string';
import { logoutAction } from 'slices/auth';

// const env = sessionStorage.getItem('env') || 'dev';

// get uri extension from url
export const apiUrl = () => {
  const path = window.location.hostname;
  if (path.includes('staging')) {
    return API_URLS.STAGING;
  }
  if (path.includes('wizardia')) {
    return API_URLS.PRODUCTION;
  }
  return API_URLS.DEV;
};

export enum ErrorCodeEnum {
  SERVER_ERROR = 500,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,
  CONFLICT = 409,
  SERVICE_UNAVAILABLE = 503,
}
export interface IExtraOptions {
  serializeResponse?: boolean;
  responseToBuffer?: boolean;
}

export interface IResponseError {
  title: string;
  status: string;
  detail?: string;
  source?: { parameter?: string; id?: string };
}

export type TArgs = Omit<RequestInit, 'body'> & {
  url: string;
  body?: any;
  params?: Record<string, any>;
};

// const desirializer = new Deserializer({ keyForAttribute: "snake_case" });

export const customBaseQuery =
  <TResult,>({
    baseUrl,
  }: {
    baseUrl: string | undefined;
  }): BaseQueryFn<TArgs, TResult, Array<IResponseError>, IExtraOptions> =>
  async (args, { dispatch, getState }, extraOptions) => {
    const { url, method = 'GET', body, params } = args;
    const { authSlice } = getState() as {
      authSlice: { access_token: string; token_type: string };
    };

    const { access_token: token, token_type: tokenType } = authSlice;
    let queryParams;

    // const serializeResponse = extraOptions?.serializeResponse ?? true;

    const headers = {
      'content-type': 'application/vnd.api+json',
      accept: 'application/vnd.api+json',
      authorization: `${tokenType} ${token}`,
      platform: 'web',
    };

    if (params) {
      queryParams = queryString.stringify(params, { arrayFormat: 'bracket' });
    }

    let requestURL = new URL(url, `${baseUrl}`).toString();

    if (queryParams) {
      requestURL = `${requestURL.toString()}?${queryParams}`;
    }

    try {
      const response = await fetch(requestURL, {
        method,
        headers,
        body: body ? JSON.stringify(body) : undefined,
      });

      let result;

      if (extraOptions?.responseToBuffer) {
        result = await response.arrayBuffer();
      } else {
        const text = await response.text();
        result = text.length ? JSON.parse(text) : undefined;
      }

      if (!result) {
        return {
          data: undefined,
        };
      }

      if ('exception' in result) {
        // eslint-disable-next-line no-throw-literal
        throw result;
      }

      // custom API error
      if ('errors' in result) {
        if (response.status === ErrorCodeEnum.UNAUTHORIZED) {
          dispatch(logoutAction());
        }
        return {
          error: result.errors as Array<IResponseError>,
        };
      }

      // if (method === "GET" && serializeResponse) {
      //   return {
      //     data: (await desirializer.deserialize(result)) as TResult,
      //   };
      // }

      return { data: result };
    } catch (error) {
      return {
        data: undefined,
      };
    }
  };

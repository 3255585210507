import React from 'react';
import styled from 'styled-components';

import { Typography } from 'components';
import { IHeaderElement } from 'constants/header';

export const handleNavigate = (url: string) => {
  if (!window) return;

  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const HeaderListItem: React.FC<IHeaderElement> = ({ name, link }) => (
  <StyledListItem color='white'>
    <StyledLink href={link} target='_blank' rel='noreferrer'>
      {name}
    </StyledLink>
  </StyledListItem>
);

const StyledLink = styled('a')`
  padding: 8px 15px;
  display: block;
  color: white;
  text-decoration: none;
  transition: color 0.2s ease-out;
  line-height: 13px;
  &:hover {
    color: ${p => p.theme.colors.primary};
  }
  ${p => `
  @media ${p.theme.breakpoints.laptop} {
    padding: 0 13px;
  }
`}
`;

const StyledListItem = styled('li')`
  list-style: none;
  cursor: pointer;
  max-width: max-content;
  font-size: 14px;
`;

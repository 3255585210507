import React, { MouseEventHandler } from 'react';
import styled, { css } from 'styled-components';

import { Spacing, theme } from '../styles/theme';

export interface IBoxProps {
  p?: Spacing;
  position?: 'static' | 'absolute' | 'relative';
  pt?: Spacing;
  pb?: Spacing;
  pl?: Spacing;
  pr?: Spacing;
  px?: Spacing;
  py?: Spacing;
  m?: Spacing | 'auto';
  mt?: Spacing | 'auto';
  mb?: Spacing | 'auto';
  ml?: Spacing | 'auto';
  mr?: Spacing | 'auto';
  my?: Spacing | 'auto';
  mx?: Spacing | 'auto';
  boxAs?: keyof JSX.IntrinsicElements;
  onClick?: MouseEventHandler;
  id?: string;
  className?: string;
  width?: string;
  maxWidth?: string;
  textAlign?: string;
  style?: React.CSSProperties;
}

export const Box: React.FC<IBoxProps> = props => {
  const { children, boxAs, onClick, id, style, ...restProps } = props;

  return (
    <StyledBox style={style} id={id} onClick={onClick} as={boxAs} {...restProps}>
      {children}
    </StyledBox>
  );
};

function withSpacing(space?: Spacing | 'auto') {
  if (!space) {
    return undefined;
  }

  return space === 'auto' ? 'auto' : theme.spacing[space];
}

export const BoxPropsCss = (p: IBoxProps) => css`
  padding: ${withSpacing(p.p)};
  padding-top: ${withSpacing(p.pt) || withSpacing(p.py)};
  padding-bottom: ${withSpacing(p.pb) || withSpacing(p.py)};
  padding-left: ${withSpacing(p.pl) || withSpacing(p.px)};
  padding-right: ${withSpacing(p.pr) || withSpacing(p.px)};
  margin: ${withSpacing(p.m)};
  margin-top: ${withSpacing(p.mt) || withSpacing(p.my)};
  margin-bottom: ${withSpacing(p.mb) || withSpacing(p.my)};
  margin-left: ${withSpacing(p.ml) || withSpacing(p.mx)};
  margin-right: ${withSpacing(p.mr) || withSpacing(p.mx)};
  width: ${p.width};
  max-width: ${p.maxWidth};
  position: ${p.position};
  text-align: ${p.textAlign};
`;

const StyledBox = styled.div<IBoxProps>`
  ${BoxPropsCss}
`;

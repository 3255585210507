/* eslint-disable camelcase */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// import { API_DOMAIN } from 'config/api';
import { apiUrl } from 'services/baseQuery';

interface IVerifyRecaptcha {
  'g-recaptcha-response': string;
}
// TODO: need to move to constants
export const recaptchaApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: apiUrl() }),
  reducerPath: 'recaptchaApi',
  endpoints: builder => ({
    verifyRecaptcha: builder.mutation<any, IVerifyRecaptcha>({
      query: body => ({
        url: '/api/validate-recaptcha',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useVerifyRecaptchaMutation } = recaptchaApi;

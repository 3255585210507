type Fonts = keyof typeof fonts;
type Colors = keyof typeof colors;
type Spacing = keyof typeof spacing;

const spacing = {
  0: '0rem',
  0.125: '0.125rem',
  0.25: '0.25rem',
  0.313: '0.313rem',
  0.5: '0.5rem',
  0.6: '0.6rem',
  0.625: '0.625rem',
  0.75: '0.75rem',
  0.875: '0.875rem',
  1: '1rem',
  1.25: '1.25rem',
  1.5: '1.5rem',
  1.75: '1.75rem',
  1.875: '1.875rem',
  2: '2rem',
  2.2: '2.2rem',
  2.5: '2.5rem',
  3: '3rem',
  3.125: '3.125rem',
  3.25: '3.25rem',
  3.375: '3.375rem',
  3.5: '3.5rem',
  3.75: '3.75rem',
  4: '4rem',
  4.125: '4.125rem',
  4.5: '4.5rem',
  4.625: '4.625rem',
  4.875: '4.875rem',
  5: '5rem',
  5.55: '5.55rem',
  5.7: '5.7rem',
  6.1: '6.1rem',
  6.25: '6.25rem',
  6.5: '6.5rem',
  8: '8rem',
  9.4: '9.4rem',
  7.125: '7.125rem',
  18: '18rem',
};

const fontWeight = {
  light: 400,
  regular: 500,
  normal: 600,
  bold: 700,
  black: 800,
};

const breakpoints = {
  mobile: '(max-width: 46rem )', // 767px
  tablet: '(max-width: 68.75rem)', // 1100px
  xsMobile: '(max-width: 25rem)', //  400px
  laptop: '(max-width: 90rem)', // 1400px
};

const fontSize = {
  '4xl': '3.875rem', // 62px
  xxxl: '2.375rem', // 38px
  xxl: '2rem', // 32px
  xl: '1.75rem', // 28px
  ml: '1.5rem', // 24px
  l: '1.25rem', // 20px
  m: '1.125rem', // 18px
  sm: '1rem', // 16px,
  s: '0.875rem', // 14px
  xs: '0.75rem', // 12px
  xxs: '0.625rem', // 10px
};

const fonts = {
  h1: {
    fontSize: fontSize['4xl'],
    fontWeight: fontWeight.light,
    lineHeight: '4.65rem',
    fontFamily: 'Equinox',
    upperCase: true,
  },
  h2: {
    fontSize: fontSize.xxxl,
    fontWeight: fontWeight.light,
    lineHeight: '3.087rem',
    fontFamily: 'Equinox',
    upperCase: true,
  },
  h3: {
    fontSize: fontSize.m,
    fontWeight: fontWeight.bold,
    lineHeight: '1.35rem',
    fontFamily: 'Montserrat, sans-serif',
  },
  h4: {
    fontSize: fontSize.s,
    fontWeight: fontWeight.bold,
    lineHeight: '1.35rem',
    fontFamily: 'Montserrat, sans-serif',
  },
  h5: {
    fontSize: fontSize.s,
    fontWeight: fontWeight.bold,
    lineHeight: '0.75rem',
    fontFamily: 'Montserrat, sans-serif',
  },
  p1: {
    fontSize: fontSize.l,
    fontWeight: fontWeight.light,
    lineHeight: '2rem',
    fontFamily: 'Montserrat, sans-serif',
  },
  p2: {
    fontSize: fontSize.s,
    fontWeight: fontWeight.regular,
    lineHeight: '0.875rem',
    fontFamily: 'Montserrat, sans-serif',
  },
  p3: {
    fontSize: fontSize.xxs,
    fontWeight: fontWeight.regular,
    lineHeight: '1rem',
    fontFamily: 'Montserrat, sans-serif',
  },
  'header-button': {
    fontSize: fontSize.xs,
    fontWeight: fontWeight.bold,
    lineHeight: '0.75rem',
    fontFamily: 'Montserrat, sans-serif',
  },
  'button-regular': {
    fontSize: fontSize.s,
    fontWeight: fontWeight.bold,
    lineHeight: '0.75rem',
    fontFamily: 'Montserrat, sans-serif',
  },
};

const fontsMobile = {
  h1: {
    fontSize: fontSize.xxxl,
    fontWeight: fontWeight.light,
    lineHeight: '2.85rem',
    fontFamily: 'Equinox',
    upperCase: true,
  },
  h2: {
    fontSize: fontSize.xl,
    fontWeight: fontWeight.light,
    lineHeight: '1.75rem',
    fontFamily: 'Equinox',
    upperCase: true,
  },
  h3: {
    fontSize: fontSize.m,
    fontWeight: fontWeight.bold,
    lineHeight: '1.35rem',
    fontFamily: 'Montserrat, sans-serif',
  },
  h4: {
    fontSize: fontSize.s,
    fontWeight: fontWeight.bold,
    lineHeight: '1.35rem',
    fontFamily: 'Montserrat, sans-serif',
  },
  h5: {
    fontSize: fontSize.xs,
    fontWeight: fontWeight.bold,
    lineHeight: '0.75rem',
    fontFamily: 'Montserrat, sans-serif',
  },
  p1: {
    fontSize: fontSize.sm,
    fontWeight: fontWeight.light,
    lineHeight: '1.4rem',
    fontFamily: 'Montserrat, sans-serif',
  },
  p2: {
    fontSize: fontSize.s,
    fontWeight: fontWeight.regular,
    lineHeight: '0.875rem',
    fontFamily: 'Montserrat, sans-serif',
  },
  p3: {
    fontSize: fontSize.xxs,
    fontWeight: fontWeight.regular,
    lineHeight: '1rem',
    fontFamily: 'Montserrat, sans-serif',
  },
  'header-button': {
    fontSize: fontSize.xs,
    fontWeight: fontWeight.bold,
    lineHeight: '0.75rem',
    fontFamily: 'Montserrat, sans-serif',
  },
  'button-regular': {
    fontSize: fontSize.s,
    fontWeight: fontWeight.bold,
    lineHeight: '0.75rem',
    fontFamily: 'Montserrat, sans-serif',
  },
};
// @TODO: After development process update naming
const colors = {
  primary: '#7AEBE4',
  white: '#FFFFFF',
  black: '#0E0F10',
  yellow: '#FFDF70',
  red: '#FF4D42',
  blue: '#458FFF',
  purple: '#CD63FF',
  darkWhite: '#FFFFFF80',
  grey: '#595959',
};

export const theme = {
  breakpoints,
  fonts,
  fontsMobile,
  colors,
  spacing,
};

export type { Fonts, Colors, Spacing };

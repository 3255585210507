import { AnyAction, combineReducers, configureStore, Reducer } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authSliceReducer, { logoutAction } from 'slices/auth';
import { userSlice } from 'slices/user/userSlice';

import { giveawaysApi } from 'services/api/airdrops';
import authReducer, { authApi } from 'services/api/auth';
import { tokenApi } from 'services/api/token';
import tokenSliceReducer from 'slices/tokenSlice';

const authPersistConfig = {
  key: 'auth',
  storage,
};

const appReducer = combineReducers({
  user: userSlice.reducer,
  token: tokenSliceReducer,
  authSlice: persistReducer(authPersistConfig, authSliceReducer),
  [authApi.reducerPath]: authReducer,
  [giveawaysApi.reducerPath]: giveawaysApi.reducer,
  [tokenApi.reducerPath]: tokenApi.reducer,
});

type TAppReducer = ReturnType<typeof appReducer>;

// TODO why is this unused?
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const rootReducer: Reducer<TAppReducer, AnyAction> = (state, action) => {
  if (logoutAction.match(action)) {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, appReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV === 'development',
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'orderApi/executeQuery/fulfilled',
          'historyApi/executeQuery/fulfilled',
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
        ],
      },
    }).concat([authApi.middleware]),
});

export type TRootState = ReturnType<typeof appReducer>;
export type TAppDispatch = typeof store.dispatch;
export interface IAppState extends ReturnType<typeof store.getState> {}

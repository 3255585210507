import { FOOTER_LABELS } from '../data';
import {
  SFooterDisclaimer,
  SFooterDisclaimerDescription,
  SFooterDisclaimerText,
} from './FooterDisclaimer.styles';

const FooterDisclaimer = () => (
  <SFooterDisclaimer>
    <SFooterDisclaimerText>{FOOTER_LABELS.disclaimer.title}</SFooterDisclaimerText>
    <SFooterDisclaimerDescription>
      {FOOTER_LABELS.disclaimer.description}
    </SFooterDisclaimerDescription>
  </SFooterDisclaimer>
);

export default FooterDisclaimer;

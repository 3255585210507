import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { routeConfig } from 'config/routeConfig';
// import { handleNavigate } from 'layouts/components/header/HeaderListItem';
import { setGiveawayId } from 'slices/user/userSlice';
import styled from 'styled-components';
import { useQuery } from 'styles/useQuery';

import { Box, Typography } from 'components';
import { PrimaryButton } from 'components/buttons/PrimaryButton';
import { formatDate } from 'helpers/misc';
import { IGiveaway } from 'services/api/airdrops';

import { PrizeBlock } from './PrizeBlock';

interface IModalContent {
  prize: string;
  currency: string;
  paidAt: string | null;
  giveaway: IGiveaway;
  isWizardiaGiveAway: boolean;
  partner: string;
  bem?: string;
  contact?: string;
}

export const ModalContent: React.FC<IModalContent> = ({
  prize,
  currency,
  bem,
  isWizardiaGiveAway,
  giveaway,
  paidAt,
  partner,
  contact,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isXsMobile, isMobile, isTablet } = useQuery();

  useEffect(() => {
    dispatch(setGiveawayId({ id: Number(giveaway.giveaway_id) }));
  }, [dispatch, giveaway.giveaway_id]);

  const buttonActions = () => {
    if (!paidAt && !bem && isWizardiaGiveAway) {
      return {
        text: 'Add address',
        action: () => history.push(routeConfig.bepAddress.self),
      };
    }
    return null;
  };

  const formatText = () => {
    if (isWizardiaGiveAway) {
      if (bem && paidAt) {
        return `We already sent your prize on ${formatDate(
          paidAt,
        )}. Please check your wallet.`;
      }
      if (!bem && !paidAt) {
        return 'Congratulations! To claim your reward provide your wallet address in the next step';
      }
      if (paidAt && !bem) {
        return 'You have already claimed this prize';
      }
      return "You'll be paid within 7 days";
    }
    if (!paidAt) {
      return `Congratulations! To claim your reward please contact ${partner}`;
    }
    return 'You have already claimed this prize';
  };

  const actions = buttonActions();
  return (
    <div>
      <PrizeBlock currency={currency} prizeAmount={prize} />
      <Typography mt={1.25} maxWidth='27.563rem' align='center' type='p1'>
        {formatText()}
      </Typography>
      {bem && (
        <StyledBSCLink
          href={String(new URL(`address/${bem}`, 'https://bscscan.com'))}
          target='_blank'
          rel='noreferrer'
        >
          {bem.slice(0, 5) + '...' + bem.slice(-4)}
        </StyledBSCLink>
      )}
      {actions && (
        <PrimaryButton
          onClick={actions.action}
          height='3.75rem'
          mt={2.5}
          maxWidth='100%'
          width='100%'
        >
          {actions.text}
        </PrimaryButton>
      )}
      {contact && (
        <Typography mt={1} align='center' color='primary' type='p2'>
          {contact}
        </Typography>
      )}
      {paidAt && !bem && (
        <ClaimedWrapper>
          <Typography align='center' type='p2'>
            Claimed it on {formatDate(paidAt)}
          </Typography>
        </ClaimedWrapper>
      )}
    </div>
  );
};

const StyledBSCLink = styled('a')`
  display: inline-block;
  color: ${p => p.theme.colors.white};
  background: ${p => p.theme.colors.grey};
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 40px;
  text-decoration: none;
`;

const ClaimedWrapper = styled(Box)`
  padding: 1.188rem 1.875rem;
  background: rgba(255, 255, 255, 0.2);
  max-width: fit-content;
  margin: 2.5rem auto 0;
  border-radius: 2.5rem;
`;

import React from 'react';
import styled from 'styled-components';

import { capitalizeFirstOnly } from 'helpers/misc';

import Clock from './icons/Clock';
import Discord from './icons/Discord';
import Facebook from './icons/Facebook';
import Linktr from './icons/Linktr';
import Logo from './icons/Logo';
import Medium from './icons/Medium';
import Telegram from './icons/Telegram';
import TikTok from './icons/TikTok';
import Twitter from './icons/Twitter';
import Youtube from './icons/Youtube';

interface IStyles {
  height?: string;
  width?: string;
  margin?: string;
}

interface IProps extends IStyles {
  name: string;
}

interface IIcons {
  [key: string]: React.FC;
}

const icons: IIcons = {
  Logo,
  Discord,
  Facebook,
  Linktr,
  Medium,
  Telegram,
  Twitter,
  TikTok,
  Clock,
  Youtube,
};

export const Icon: React.FC<IProps> = ({ name, ...rest }) => {
  const convertToPascalCase = (input: string = '') =>
    capitalizeFirstOnly(
      input.split('_').reduce((word, part) => word + capitalizeFirstOnly(part)),
    );

  const IconComponent: React.FC = icons[convertToPascalCase(name)];

  if (!IconComponent) {
    return null;
  }

  return (
    <IconWrapper {...rest}>
      <IconComponent />
    </IconWrapper>
  );
};

const IconWrapper = styled.span<IStyles>`
  svg {
    display: block;
    margin: ${props => (props.margin ? props.margin : '0')};
    width: ${props => (props.width ? props.width : '100%')};
    height: ${props => (props.height ? props.height : '100%')};
  }
`;

import { externalLinks, FOOTER_LABELS } from '../data';
import {
  LinksWrapper,
  SCopyright,
  SCopyrightLink,
  SCopyrightLinksText,
  SCopyrightText,
} from './Copyright.styles';

const Copyright = () => (
  <SCopyright>
    <SCopyrightText>{FOOTER_LABELS.copyright.rightsReserved}</SCopyrightText>
    <LinksWrapper>
      <SCopyrightLink
        href={externalLinks.wizardiaPrivacyPolicy}
        target='_blank'
        rel='noreferrer'
      >
        <SCopyrightLinksText>{FOOTER_LABELS.copyright.privacy}</SCopyrightLinksText>
      </SCopyrightLink>
      <SCopyrightLink
        href={externalLinks.wizardiaTermsAndConditions}
        target='_blank'
        rel='noreferrer'
      >
        <SCopyrightLinksText>{FOOTER_LABELS.copyright.terms}</SCopyrightLinksText>
      </SCopyrightLink>
    </LinksWrapper>
  </SCopyright>
);

export default Copyright;

import React from 'react';
import { Icon } from 'assets/Icon';
import Dashline from 'assets/images/dashed_line.png';

import { headerLinks } from '../data';
import Dropdown from '../Dropdown/Dropdown';
import HeaderLinksCollapsableContainer from '../HeaderLinksCollapsableContainer/HeaderLinksCollapsableContainer';
import {
  DesktopLinks,
  DropdownLink,
  DropdownLinkWrapper,
  DropdownText,
  HeaderLinksWrapper,
  MobileDropdownItemText,
  MobileDropdownLink,
  MobileHeaderLink,
  MobileHeaderLinksImage,
  MobileHeaderLinkTitleText,
  MobileLinks,
  SocialImageWrapper,
  Text,
  TextLink,
} from './HeaderLinksUpdated.styles';

interface IHeaderLinks {
  onLinkClick?: () => void;
}

export const HeaderLinks: React.FC<IHeaderLinks> = ({ onLinkClick }) => (
  <HeaderLinksWrapper>
    {headerLinks.map((item, index) => (
      <React.Fragment key={item.id}>
        <MobileLinks>
          {!item.is_dropdown && (
            <>
              {index !== 0 && <MobileHeaderLinksImage src={Dashline} />}
              <MobileHeaderLink
                href={item.url}
                onClick={onLinkClick}
                target='_blank'
                rel='noreferrer'
              >
                <MobileHeaderLinkTitleText>{item.link_text}</MobileHeaderLinkTitleText>
              </MobileHeaderLink>
              {index === headerLinks.length - 1 && (
                <MobileHeaderLinksImage src={Dashline} />
              )}
            </>
          )}
          {!!item.is_dropdown && item.items && (
            <>
              {index !== 0 && <MobileHeaderLinksImage src={Dashline} />}
              <HeaderLinksCollapsableContainer
                title={
                  <MobileHeaderLinkTitleText>{item.link_text}</MobileHeaderLinkTitleText>
                }
              >
                {item.items.map(dropdownItem => (
                  <MobileDropdownLink
                    key={dropdownItem.id}
                    onClick={onLinkClick}
                    href={dropdownItem.url}
                  >
                    <MobileDropdownItemText>
                      {dropdownItem.link_text}
                    </MobileDropdownItemText>
                  </MobileDropdownLink>
                ))}
              </HeaderLinksCollapsableContainer>
              {index === headerLinks.length - 1 && (
                <MobileHeaderLinksImage src={Dashline} />
              )}
            </>
          )}
        </MobileLinks>
        <DesktopLinks>
          {!item.is_dropdown && (
            <TextLink href={item.url} target='_blank'>
              <Text>{item.link_text}</Text>
            </TextLink>
          )}
          {!!item.is_dropdown && item.items && (
            <Dropdown
              item={item}
              label={item.link_text}
              labelUrl={item.url}
              items={item.items.map(dropdownItem => (
                <DropdownLink
                  key={dropdownItem.id}
                  href={dropdownItem.url}
                  target='_blank'
                >
                  <DropdownLinkWrapper>
                    {dropdownItem?.image && (
                      <SocialImageWrapper>
                        <Icon name={dropdownItem.image} />
                      </SocialImageWrapper>
                    )}
                    <DropdownText>{dropdownItem.link_text}</DropdownText>
                  </DropdownLinkWrapper>
                </DropdownLink>
              ))}
            />
          )}
        </DesktopLinks>
      </React.Fragment>
    ))}
  </HeaderLinksWrapper>
);

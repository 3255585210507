import React from 'react';
import { useLocation } from 'react-router-dom';
import background from 'images/background.png';
import FooterUpdated from 'layouts/components/footerUpdated/FooterUpdated';
import HeaderUpdated from 'layouts/components/headerUpdated/HeaderUpdated';
import styled from 'styled-components';

import { Loader } from 'components/other/Loading';
import { useLoaded } from 'hooks/use-loaded';

const EXCLUDE_FOOTER = ['/bep-address'];

export const BodyWrapper: React.FC = ({ children }) => {
  const loaded = useLoaded();
  const { pathname } = useLocation();
  const includeFooter = !EXCLUDE_FOOTER.includes(pathname);
  return (
    <>
      <Loader loading={!loaded} />
      <ContentBox>
        <HeaderUpdated />
        <Main id='main'>{children}</Main>
        {includeFooter && <FooterUpdated />}
      </ContentBox>
    </>
  );
};

const ContentBox = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  padding-top: 5.375rem;

  ${p => `
    @media ${p.theme.breakpoints.mobile} {
    overflow-y: hidden;
    padding-top: 2.5rem;
    }
  `}
`;

const Main = styled.main``;

import styled from 'styled-components/macro';

export const SFooter = styled.footer`
  position: relative;
  z-index: 1;
  background-color: rgb(14, 15, 16);
`;

export const SFooterOuter = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

export const SFooterTop = styled.div`
  display: flex;
  border-top-width: 0.0625rem;
  flex-direction: column;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    position: relative;
    align-items: center;
    padding-top: 0.5rem;
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const SFooterInner = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`;

export const SFooterBottom = styled.div`
  flex-direction: column;
  @media screen and (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    padding: 0 0 36px 0;
  }
`;

export const SFooterColumns = styled.div`
  display: flex;
  padding-top: 45px;
  flex-wrap: wrap;
  row-gap: 3.125rem;
  justify-content: space-between;
  align-items: start;
  flex-direction: row;
  margin-bottom: 3.125rem;

  @media screen and (min-width: 768px) {
    margin-bottom: 90px;
    padding-top: 85px;
    flex-basis: 70%;
    max-width: 70%;
  }

  @media screen and (min-width: 1024px) {
    flex-basis: 100%;
    max-width: 100%;
  }
`;

export const SFooterGuideWrapper = styled.div`
  margin: 0 0 7.5rem 0;
`;

export const SocialIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 360px;
  column-gap: 25px;
  padding-top: 50px;

  @media screen and (min-width: 768px) {
    column-gap: 30px;
    padding-top: 0;
  }
`;

export const SocialIconLink = styled.a`
  width: 20px;

  &:hover {
    svg {
      path {
        fill: ${p => p.theme.colors.primary};
      }
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  position: relative;
  padding: 25px 0 0;
  background-image: linear-gradient(
    90deg,
    hsla(0, 0%, 100%, 0.5) 10%,
    hsla(0, 0%, 100%, 0) 0
  );
  background-position: top;
  background-size: 10px 2px;
  background-repeat: repeat-x;
  @media screen and (min-width: 768px) {
    padding: 46px 0 0;
  }
`;

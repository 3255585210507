import { useMediaQuery } from 'react-responsive';

import { theme } from './theme';

const { breakpoints } = theme;
export const useQuery = () => ({
  isMobile: useMediaQuery({ query: breakpoints.mobile }),
  isTablet: useMediaQuery({ query: breakpoints.tablet }),
  isXsMobile: useMediaQuery({ query: breakpoints.xsMobile }),
  isLaptop: useMediaQuery({ query: breakpoints.laptop }),
});

import { include } from 'named-urls';

export const routeConfig = {
  login: include('/login', {
    self: '',
    createPassword: 'create-password',
  }),
  register: include('/register', {
    self: '',
  }),
  home: '/',
  myDrops: include('/airdrops', {
    self: '',
  }),
  bepAddress: include('/bep-address', {
    self: '',
  }),
};

// import {
//   IAuthClient,
//   IChangePasswordPayload,
//   IChangePasswordSuccessResponse,
//   IClientPayload,
//   IGenericSuccessResponse,
//   IGetChangePasswordQuestion,
//   IGetChangePasswordQuestionPayload,
//   IGetTokenSuccessResponse,
//   IPhoneCheckSuccessResponse,
//   IRegisterConfirmPayload,
//   IUpdatePassword,
//   IUpdatePasswordPayload,
// } from "@monorepo/types";
import { createApi } from '@reduxjs/toolkit/query/react';
import { API_DOMAIN } from 'config/api';

import { customBaseQuery } from 'services/baseQuery';

import { IGenericSuccessResponse } from './generic';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: customBaseQuery<{ meta: IGenericSuccessResponse }>({
    baseUrl: API_DOMAIN,
  }),
  tagTypes: ['Client'],
  endpoints: builder => ({
    client: builder.query<any, void>({
      query: () => ({
        url: '/api/v1/clients',
      }),
      providesTags: ['Client'],
    }),

    // updateProfile: builder.mutation<IAuthClient, IClientPayload>({
    //   query: (payload) => ({
    //     url: "/api/v1/client",
    //     method: "PUT",
    //     body: payload,
    //   }),
    //   invalidatesTags: (result) => {
    //     if (result) {
    //       return ["Client"];
    //     }
    //     return [];
    //   },
    // }),
  }),
});

export const { useClientQuery } = authApi;

export default authApi.reducer;

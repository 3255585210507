import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { routeConfig } from 'config/routeConfig';

import { BodyWrapper, Box, Container, Typography } from 'components';
import { PrimaryButton } from 'components/buttons/PrimaryButton';
import { Input, InputTypeEnum } from 'components/inputs/Input';
import { Section } from 'components/wrappers/Section';
import { useUser } from 'hooks/use-user';
import { useValidateBepMutation } from 'services/api/token';
import { StorageService } from 'services/StorageService';

export const Address = () => {
  const { user } = useUser();
  const history = useHistory();
  const [error, setError] = useState<string | null>(null);
  const [startBep, _data] = useValidateBepMutation();
  const [input, setInput] = useState('');

  const token = StorageService.getAccessToken();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async () => {
    if (!input || !token) return;
    setError(null);
    try {
      const result = await startBep({
        email: user.email,
        bep20_address: input,
        token,
        giveaway_id: user.selectedGiveawayId,
      }).unwrap();
      if (result.error) {
        if (result.message?.includes('Token')) {
          history.push(routeConfig.home);
        }
        setError(result.message);
        return;
      }
      history.push(routeConfig.myDrops.self);
    } catch (err: any) {
      setError(err.message);
    }
  };

  if (!user.email || !user.selectedGiveawayId || !token) {
    history.push(routeConfig.home);
  }
  return (
    <BodyWrapper>
      <Section margin='12.5rem 0 7.5rem'>
        <Container>
          <Box textAlign='center' m='auto' maxWidth='36.625rem'>
            <Typography mb={2.5} type='h1' align='center'>
              Add bep address
            </Typography>
            <Input
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  handleSubmit();
                }
              }}
              type={InputTypeEnum.Email}
              value={input}
              label='Enter BEP20 address'
              name='bep-20'
              onChange={e => setInput(e.target.value)}
            />
            {error && (
              <Typography color='red' mt={1} align='center' type='p2'>
                {error}
              </Typography>
            )}
            <PrimaryButton
              disabled={!input.length}
              onClick={handleSubmit}
              height='3.75rem'
              width='100%'
              maxWidth='11.5rem'
              mt={1.875}
            >
              Submit
            </PrimaryButton>
          </Box>
        </Container>
      </Section>
    </BodyWrapper>
  );
};

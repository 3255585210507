import React, { MouseEventHandler, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Colors, Fonts } from 'styles/theme';

import { IBoxProps } from 'components/Box';
import { RelativeLoader } from 'components/other/RelativeLoader';
import { Typography } from 'components/Typography';
// import { FlexWrapper } from 'components/wrappers';

export interface IButton extends IBoxProps {
  width?: string;
  height?: string;
  type?: Fonts;
  loading?: boolean;
  disabled?: boolean;
  maxWidth?: string;
  shadow?: boolean;
  color?: Colors;
  textColor?: Colors;
  onClick?: MouseEventHandler;
  style?: React.CSSProperties;
}

export const PrimaryButton = ({
  loading,
  width,
  height,
  type = 'h5',
  disabled,
  maxWidth,
  onClick,
  // color = 'primary',
  textColor = 'white',
  id,
  children,
  // shadow = false,
  style,
  ...boxProps
}: PropsWithChildren<IButton>) => (
  <Button
    id={id}
    width={width}
    maxWidth={maxWidth}
    onClick={onClick}
    color={textColor}
    type={type}
    disabled={disabled || loading}
    align='center'
    height={height}
    boxAs='button'
    style={style}
    {...boxProps}
  >
    {!loading && children}
    {loading && <RelativeLoader loading={loading} />}
  </Button>
);

const Button = styled(Typography)<PropsWithChildren<IButton>>`
  max-width: ${p => p.maxWidth || 'fit-content'};
  cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')};
  padding: 1rem 1.313rem;
  border-radius: 2.5rem;
  box-shadow: inset 0px 0px 20px rgba(122, 235, 228, 0.8);
  background: rgba(122, 235, 228, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid ${p => p.theme.colors.primary};
  height: ${p => p.height || 'auto'};
  transition: all 0.5s;
  &:hover {
    background: ${p =>
      p.disabled ? 'rgba(122, 235, 228, 0.1)' : 'rgba(122, 235, 228, 0.3)'};
  }
`;

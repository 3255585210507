// import React, { useEffect, useRef } from 'react';
// import test from 'assets/images/cardImage.png';
import styled from 'styled-components';
import { useQuery } from 'styles/useQuery';

import { Container, GridWrapper, Typography } from 'components';
import { Section } from 'components/wrappers/Section';
import { WinnerCard } from 'pages/home/elements/WinnerCard';
import { useGetGiveAwaysQuery } from 'services/api/airdrops';
import { useEffect } from 'react';

// const CARDS: Array<IWinnerCard> = [
//   {
//     title: 'Wizardia x DAOLaunch Airdrop',
//     image: test,
//     endDate: 'Ends in 2 days',
//     prizePool: '$3 110 USDT Prize pool',
//     winners: 'with 21 winners',
//   },
//   {
//     title: 'Wizardia x DAOLaunch Airdrop',
//     image: test,
//     endDate: 'Ends in 2 days',
//     prizePool: '$3 110 USDT Prize pool',
//     winners: 'with 21 winners',
//   },
//   {
//     title: 'Wizardia x DAOLaunch Airdrop',
//     image: test,
//     endDate: 'Ends in 2 days',
//     prizePool: '$3 110 USDT Prize pool',
//     winners: 'with 21 winners',
//   },
//   {
//     title: 'Wizardia x DAOLaunch Airdrop',
//     image: test,
//     endDate: 'Ends in 2 days',
//     prizePool: '$3 110 USDT Prize pool',
//     winners: 'with 21 winners',
//   },
//   {
//     title: 'Wizardia x DAOLaunch Airdrop',
//     image: test,
//     endDate: 'Ends in 2 days',
//     prizePool: '$3 110 USDT Prize pool',
//     winners: 'with 21 winners',
//   },
// ];

const getGridColumns = (isXsMobile: boolean, isTablet: boolean) => {
  if (isXsMobile) return 1;
  if (isTablet) return 3;

  return 4;
};

export const Winners = () => {
  const {
    isTablet,
    isMobile,
    // isXsMobile
  } = useQuery();
  const { data, refetch: refetchData } = useGetGiveAwaysQuery({});
  const columns = getGridColumns(isMobile, isTablet);

  useEffect(() => {
    refetchData();
  }, []);

  if (!data) return null;
  return (
    <Section margin={isMobile ? '9.375rem 0 12.625rem' : '12.5rem 0 7.5rem'}>
      <Container>
        <Title align='center' type='h3'>
          Wizardia airdrops & winners
        </Title>
        <GridWrapper
          margin={isMobile ? '2.875rem 0 0' : '5rem 0 0'}
          gap='1.25rem 1.375rem'
          columns={columns}
        >
          {[...data.giveaways]
            .sort(
              (a, b) => new Date(b.ended_at).valueOf() - new Date(a.ended_at).valueOf(),
            )
            .map((item, index) => (
              <WinnerCard key={item.id + String(index)} {...item} />
            ))}
        </GridWrapper>
      </Container>
    </Section>
  );
};

const Title = styled(Typography)`
&:before,
&:after {
 background-color: #000;
  content: "";
  display: inline-block;
  height: 1px;
  text-align: center;
  position: relative;
  vertical-align: middle;
  width: 50%;
  background-image: linear-gradient(90deg,hsla(0,0%,100%,1) 10%,hsla(0,0%,100%,0) 0);
  background-position: top;
  background-size: 10px 2px;
  background-repeat: repeat-x;
}

&:before {
  right: 1rem;
  margin-left: -50%;
}

&:after {
  left: 1rem;
  margin-right: -50%;
}
}
`;

export const FOOTER_LINKS = {
  footer_game: [
    {
      id: 101,
      navigation_type: 'game',
      link_type: 'text',
      link_text: 'About the game',
      url: 'https://wizardia.io/about-the-game',
      icon_path: null,
      order_number: 1,
    },
    {
      id: 102,
      navigation_type: 'game',
      link_type: 'text',
      link_text: 'Tournaments',
      url: 'https://wizardia.io/tournaments',
      icon_path: null,
      order_number: 2,
    },
    {
      id: 103,
      navigation_type: 'game',
      link_type: 'text',
      link_text: 'Winners',
      url: 'https://wizardia.io/tournaments/winners',
      icon_path: null,
      order_number: 3,
    },
  ],
  footer_shop: [
    {
      id: 104,
      navigation_type: 'shop',
      link_type: 'text',
      link_text: 'Arena NFTs',
      url: 'https://wizardia.io/nft-sale',
      icon_path: null,
      order_number: 4,
    },
    {
      id: 105,
      navigation_type: 'shop',
      link_type: 'text',
      link_text: 'Market NFTs',
      url: 'https://wizardia.io/market-nft',
      icon_path: null,
      order_number: 5,
    },
    {
      id: 106,
      navigation_type: 'shop',
      link_type: 'text',
      link_text: 'Wizard NFTs',
      url: 'https://wizardia.io/collection',
      icon_path: null,
      order_number: 6,
    },
    {
      id: 107,
      navigation_type: 'shop',
      link_type: 'text',
      link_text: 'Consumables',
      url: 'https://wizardia.io/consumables',
      icon_path: null,
      order_number: 7,
    },
  ],
  footer_token_menu: [
    {
      id: 28,
      navigation_type: 'token',
      link_type: 'text',
      link_text: 'Staking',
      url: 'https://staking.wizardia.io',
      icon_path: null,
      order_number: 8,
    },
    {
      id: 29,
      navigation_type: 'token',
      link_type: 'text',
      link_text: 'Tokenomics',
      url: 'https://wizardia.io/tokenomics',
      icon_path: null,
      order_number: 9,
    },
    {
      id: 30,
      navigation_type: 'token',
      link_type: 'text',
      link_text: 'Buy on CEX',
      url: 'https://www.gate.io/trade/WZRD_USDT',
      icon_path: null,
      order_number: 10,
    },
  ],
  footer_wizardians: [
    {
      id: 110,
      navigation_type: 'wizardians',
      link_type: 'text',
      link_text: 'Blog',
      url: 'https://wizardia.io/blog',
      icon_path: null,
      order_number: 13,
    },
    {
      id: 28,
      navigation_type: 'wizardians',
      link_type: 'text',
      link_text: 'Partners program',
      url: 'https://wizardia.io/partnership',
      icon_path: null,
      order_number: 14,
    },
    {
      id: 25,
      navigation_type: 'wizardians',
      link_type: 'text',
      link_text: 'Verify Wizardia links',
      url: 'https://wizardia.io/verification',
      icon_path: null,
      order_number: 16,
    },
  ],
  footer_legal_menu: [
    {
      id: 34,
      navigation_type: 'legal',
      link_type: 'text',
      link_text: 'Roadmap',
      url: 'https://wizardia.io/#roadmap',
      icon_path: null,
      order_number: 17,
    },
    {
      id: 35,
      navigation_type: 'legal',
      link_type: 'text',
      link_text: 'Lightpaper',
      url: 'https://lightpaper.wizardia.io/',
      icon_path: null,
      order_number: 18,
    },
    {
      id: 36,
      navigation_type: 'legal',
      link_type: 'text',
      link_text: 'Wizardia pitch deck',
      url: 'https://wizardia.io/pitch-deck.pdf',
      icon_path: null,
      order_number: 19,
    },
    {
      id: 22,
      navigation_type: 'legal',
      link_type: 'text',
      link_text: 'All Wizards',
      url: 'https://wizardia.io/wizards',
      icon_path: null,
      order_number: 20,
    },
  ],
};

export const FOOTER_LABELS = {
  menu: {
    overview: 'Overview',
    wzrdToken: '$WZRD Token',
    legal: 'Legal',
    game: 'Game',
    shop: 'Shop',
    wizardians: 'Wizardians',
  },
  disclaimer: {
    title: 'Disclaimer',
    description:
      'Nothing in this website constitutes financial advice, and it is always recommended to consult a qualified financial advisor before participating in any token or NFT purchases',
  },
  copyright: {
    rightsReserved: '© Wizardia. All rights reserved.',
    privacy: 'Privacy Policy',
    terms: 'Terms of Service',
  },
  'footer-overview-menu': {
    'How to earn?': 'How to earn?',
    Team: 'Team',
    'Arena NFTs': 'Arena NFTs',
    'Wizard NFTs': 'Wizard NFTs',
    'Market NFTs': 'Market NFTs',
    Shop: 'Shop',
    'Verify Wizardia links': 'Verify Wizardia links',
    Giveaways: 'Giveaways',
    'About the game': 'About the game',
    'Partners program': 'Partners program',
  },
  'footer-token-menu': {
    Staking: 'Staking',
    Tokenomics: 'Tokenomics',
    'Buy on CEX': 'Buy on CEX',
    'Buy on DEX': 'Buy on DEX',
    'Farm $WZRD': 'Farm $WZRD',
    'Where to buy Wizardia tokens?': 'Where to buy Wizardia tokens?',
    'Where to buy?': 'Where to buy?',
  },
  footerLegalMenu: {
    Roadmap: 'Roadmap',
    Lightpaper: 'Lightpaper',
    'Wizardia pitch deck': 'Wizardia pitch deck',
    'All Wizards': 'All Wizards',
  },
  "Don't miss our latest news": "Don't miss our latest news",
  Email: 'Email',
  Join: 'Join',
  'footer-game-menu': {
    'About the game': 'About the game',
    Tournaments: 'Tournaments',
    Winners: 'Winners',
  },
  'footer-shop-menu': {
    'Arena NFTs': 'Arena NFTs',
    'Wizard NFTs': 'Wizard NFTs',
    'Market NFTs': 'Market NFTs',
    Consumables: 'Consumables',
  },
  'footer-wizardians-menu': {
    Blog: 'Blog',
    'Partners program': 'Partners program',
    Giveaways: 'Giveaways',
    'Verify Wizardia links': 'Verify Wizardia links',
  },
};

export const externalLinks = {
  gateIo:
    'https://www.gate.io/trade/WZRD_USDT?utm_source=Wizardia&utm_medium=Tournaments&utm_campaign=buy-token',
  pancakeSwap:
    'https://pancakeswap.finance/info/pool/0xee456d906a38e10680c9d157fff143390e9681ba',
  wizardiaPrivacyPolicy: 'https://lightpaper.wizardia.io/legals/privacy-policy',
  wizardiaTermsAndConditions:
    'https://lightpaper.wizardia.io/legals/terms-and-conditions',
  partnershipsRegister: 'https://partners.wizardia.io/register',
  partnershipsLogin: 'https://partners.wizardia.io/login',
  partnershipsIntercom:
    'https://intercom.help/wizardia/en/collections/3343540-wizardia-affiliate-program',
};

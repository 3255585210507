/* eslint-disable camelcase */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// import { API_DOMAIN } from 'config/api';
import { apiUrl } from 'services/baseQuery';

interface IClaimReward {
  email: string;
}

export interface IGiveaway {
  id: number;
  giveaway_id: number;
  ended_at: string;
  hosted_by: string;
  image_src: string;
  link: string;
  prize_pool: string;
  started_at: string;
  time: string;
  title: string;
}

// TODO: need to move to constants
export const giveawaysApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: apiUrl() }),
  reducerPath: 'giveawaysApi',
  endpoints: builder => ({
    getGiveAways: builder.query<{ giveaways: Array<IGiveaway> }, {}>({
      query: () => ({
        url: '/api/giveaways',
      }),
    }),
    postGiveaways: builder.mutation<
      { email_sent: boolean; message: string },
      IClaimReward
    >({
      query: body => ({
        url: '/api/claim-reward',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useGetGiveAwaysQuery, usePostGiveawaysMutation } = giveawaysApi;

import React from 'react';
import styled from 'styled-components';

import { Box, Typography } from 'components';

import { DropList, IDrop } from '../elements/DropList';

interface IHero {
  airdrops: Array<IDrop>;
  email: string;
}

const formatText = (airdropsCount: number) =>
  airdropsCount > 1
    ? `We found ${airdropsCount} airdrops that you have won`
    : `We found ${airdropsCount} airdrop that you have won`;

export const Hero: React.FC<IHero> = ({ airdrops, email }) => (
  <>
    <EmailWrapper>
      <Typography type='p2'>{email}</Typography>
    </EmailWrapper>
    <Typography mt={1.25} align='center' type='h1'>
      Your airdrops
    </Typography>
    <Typography mt={1.25} align='center' type='p1'>
      {formatText(airdrops.length)}
    </Typography>
    <DropList airdrops={airdrops} />
  </>
);

const EmailWrapper = styled(Box)`
  background: rgba(122, 235, 228, 0.2);
  border-radius: 2.5rem;
  margin: 3.75rem auto 0;
  padding: 0.625rem 18px;
  max-width: max-content;
`;

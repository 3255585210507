const SvgComponent = () => (
  <svg width={19} height={19} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='m13.284 9.086-2.54 6.208L4.666.46H.393l8.404 20.532h3.893l2.73-6.64-2.136-5.267Z'
      fill='#fff'
    />
    <path
      d='m26.15.461-6.053 14.833-2.54-6.208-2.137 5.266 2.706 6.64h3.918L30.448.463h-4.297ZM15.421 3.845l-.925 2.29.925 2.264.926-2.264-.926-2.29ZM67.274 13.92l1.068-2.595 1.069 2.595h-2.137ZM40.158.461V4.66h11.206L40.158 15.93v5.064h15.788v-4.198H45.05L55.994 5.779V.46H40.158ZM115.467 10.74c0-5.674-4.297-10.279-9.592-10.279h-6.552V4.66h6.552c3.11 0 5.651 2.723 5.651 6.08 0 3.334-2.541 6.056-5.651 6.056h-3.988l4.89 4.172c4.867-.483 8.69-4.884 8.69-10.227Z'
      fill='#fff'
    />
    <path
      d='M104.997 12.063c.694 0 1.258-.604 1.258-1.349 0-.744-.564-1.348-1.258-1.348-.695 0-1.259.604-1.259 1.348 0 .745.564 1.349 1.259 1.349ZM68.339 5.397l4.653 11.398h-6.98v4.172H79L70.619.461h-4.56l-8.38 20.506h4.297l6.363-15.57ZM127.858.461h-4.273l-8.404 20.506h4.297l8.38-20.506ZM134.341 5.397l4.653 11.398h-6.647v4.172h12.654L136.62.461h-4.534l-8.405 20.506h4.297l6.363-15.57ZM84.65 4.66h4.772c1.805 0 3.277 1.577 3.277 3.51 0 1.934-1.472 3.511-3.276 3.511h-2.28v4.783l5.27 4.503h6.292l-6.623-5.648c2.635-1.145 4.51-3.918 4.51-7.149 0-4.249-3.228-7.709-7.193-7.709h-8.69v20.506h3.918V4.66h.023ZM36.313.461h-3.917v20.506h3.917V.461Z'
      fill='#fff'
    />
  </svg>
);

export default SvgComponent;

import styled from 'styled-components/macro';

export const HeaderLinksWrapper = styled.div`
  display: flex;
  column-gap: 0;
  margin: 0;
  flex-direction: column;
  padding-top: 24px;
  width: 100%;
  align-items: stretch;
  @media screen and (min-width: 1024px) {
    flex-direction: row;
    column-gap: 22px;
    margin: 0 auto 0 0;
    row-gap: 0;
    padding: 0 30px;
  }
`;

export const MobileHeaderLinksImage = styled.img`
  width: 100%;
  max-height: 2px;
  margin: 25px 0;
  display: block;
`;

export const MobileHeaderLinkTitleText = styled.p`
  font-size: 20px;
  line-height: 32px;
  color: white;
  text-align: left;
  @media screen and (min-width: 1024px) {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
  }
`;

export const MobileDropdownItemText = styled(MobileHeaderLinkTitleText)`
  font-size: 14px;
  line-height: 22px;
`;

export const HeaderSocialLinksWrapper = styled.div`
  width: 100%;
  text-align: -webkit-center;
  padding: 5px 20px 20px;
  display: block;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

export const SocialImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 15px;
  height: 20px;
  margin: 1px 15px 0 0;
`;

export const DropdownLinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DropdownLink = styled.a`
  text-align: left;
  text-decoration: none;
  &:hover {
    span {
      svg {
        path {
          fill: ${p => p.theme.colors.primary};
        }
      }
    }
  }
`;

export const MobileDropdownLink = styled.a`
  padding: 8px 0 8px 16px;
  text-decoration: none;
  &:hover {
    & > * {
      color: #7aebe4;
    }
  }
`;

export const MobileHeaderLink = styled.a`
  padding: 0 4px 0 0;
  text-decoration: none;
  display: block;
`;

export const TextLink = styled.a`
  text-decoration: none;
  &:hover {
    & > * {
      color: #7aebe4;
    }
  }
`;

export const DropdownText = styled(MobileHeaderLinkTitleText)`
  text-align: left;
  &:hover {
    color: #7aebe4;
  }
`;

export const Text = styled(MobileHeaderLinkTitleText)`
  text-align: center;
`;

export const MobileLinks = styled.div`
  display: block;
  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

export const DesktopLinks = styled.div`
  display: none;
  @media screen and (min-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledMobileSocialIcons = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  column-gap: 10px;
  max-width: 360px;

  > div {
    margin-left: 0;
    margin-right: 0;
  }

  @media screen and (min-width: 480px) {
    flex-wrap: wrap;
    justify-content: center;

    > div {
      margin-right: 8px;
      margin-left: 8px;
    }
  }
`;

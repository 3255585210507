import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAuthState } from 'types/services/auth';

const initialState = {} as IAuthState;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(_, action: PayloadAction<IAuthState>) {
      return action.payload;
    },
    logoutAction() {
      return initialState;
    },
    setLastActive(state, action: PayloadAction<number>) {
      return { ...state, last_active: action.payload };
    },
  },
});

export const { login, logoutAction, setLastActive } = authSlice.actions;
export default authSlice.reducer;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { HeaderLinks } from './HeaderLinks/HeaderLinksUpdated';
import { MobileMenu } from './MobileMenu/MobileMenuUpdated';
import {
  BurgerWrapper,
  ButtonWrapper,
  Container,
  HeaderWrapper,
  LinksWrapper,
  MobileMenuWrapper,
  NavButton,
  NavWrapper,
  StyledButton,
  StyledHeader,
  StyledIcon,
} from './HeaderUpdated.styles';

export interface IHeader {
  addClass?: string;
}

const HeaderUpdated: React.FC<IHeader> = () => {
  const [open, setOpen] = useState(false);

  return (
    <HeaderWrapper>
      <StyledHeader>
        <Container>
          <NavWrapper>
            <Link to='/'>
              <StyledIcon width='9.063rem' height='1.25rem' name='logo' />
            </Link>
            <>
              <LinksWrapper>
                <HeaderLinks />
              </LinksWrapper>
              <ButtonWrapper>
                <NavButton href='https://wizardia.io/tournaments' target='_blank'>
                  Play game
                </NavButton>
              </ButtonWrapper>
            </>
            <MobileMenuWrapper>
              <BurgerWrapper>
                <StyledButton onClick={() => setOpen(prev => !prev)} open={open} />
              </BurgerWrapper>
              <MobileMenu onLinkClick={() => setOpen(false)} open={open} />
            </MobileMenuWrapper>
          </NavWrapper>
        </Container>
      </StyledHeader>
    </HeaderWrapper>
  );
};

export default HeaderUpdated;

import { createSlice } from '@reduxjs/toolkit';

import { reducer } from './reducer';

export interface IUserState {
  email: string;
  selectedGiveawayId: number;
}

export const initialUserState: IUserState = {
  email: '',
  selectedGiveawayId: -1,
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: reducer,
});

export const { setEmail, setGiveawayId } = userSlice.actions;

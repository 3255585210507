import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

interface IProps {
  loading: boolean;
}

let timer: ReturnType<typeof setTimeout> | null;

export const Loader: React.FC<IProps> = ({ loading }) => {
  const [isInDOM, setIsInDOM] = useState(true);

  useEffect(
    () => () => {
      // eslint-disable-next-line no-unused-expressions
      timer && clearTimeout(timer);
    },
    [],
  );

  useEffect(() => {
    if (loading) return;
    timer = setTimeout(() => {
      setIsInDOM(false);
    }, 401);
  }, [loading]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!isInDOM) return <></>;

  return (
    <LoaderWrapper role='progressbar' loading={loading ? 1 : 0}>
      <div className='lds-ring'>
        <div />
        <div />
        <div />
        <div />
      </div>
    </LoaderWrapper>
  );
};

const LoaderWrapper = styled.div<{ loading: number }>`
  display: flex;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background: ${p => p.theme.colors.white}
  transition: opacity 400ms;
  will-change: opacity;

  ${({ loading }) =>
    !loading &&
    css`
      opacity: 0;
      pointer-events: none;
    `}

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    opacity: 1;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid black;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color:  ${p => p.theme.colors.primary} transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

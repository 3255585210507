const SvgComponent = () => (
  <svg
    width={19}
    height={19}
    viewBox='0 0 19 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M19 9.558C19 4.28 14.747 0 9.5 0S0 4.28 0 9.558c0 4.77 3.474 8.725 8.016 9.442v-6.68H5.604V9.559h2.412V7.452c0-2.395 1.418-3.718 3.588-3.718 1.039 0 2.127.186 2.127.186v2.352h-1.198c-1.18 0-1.549.737-1.549 1.494v1.792h2.635l-.421 2.763h-2.214V19C15.526 18.283 19 14.329 19 9.558Z'
      fill='#fff'
    />
  </svg>
);

export default SvgComponent;

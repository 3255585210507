import styled from 'styled-components/macro';

import { Picture } from 'components/other/Picture';

interface IMobileMenu {
  open: boolean;
}

export const MobileMenuWrapper = styled.div<IMobileMenu>`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 68px 20px 20px;
  max-width: unset;
  position: absolute;
  transition: transform 0.5s ease-out;
  top: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: -1;
  left: 0;
  height: 100vh;
  transform: ${({ open }) => (open ? 'translateY(0)' : 'translateY(-120%)')};
  text-align: center;
  opacity: ${({ open }) => (open ? 1 : 0)};
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const LinksWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledPicture = styled(Picture)<{ open: boolean }>`
  z-index: -1;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  transition: transform 0.5s ease-out;
  ${p => `
  transform: ${p.open ? 'translateY(0)' : 'translateY(-120%)'};
  `};
  display: block;
  > img,
  source {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;

export const SocialIconLink = styled.a`
  width: 20px;

  &:hover {
    svg {
      path {
        fill: ${p => p.theme.colors.primary};
      }
    }
  }
`;

export const SocialIconsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  column-gap: 20px;
  max-width: 360px;
  margin: 10px auto 0;
  height: 20px;
  padding: 0 20px;

  @media screen and (min-width: 480px) {
    justify-content: center;
  }
`;

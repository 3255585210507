import React from 'react';
import styled from 'styled-components';

import { IBoxProps } from 'components/Box';

interface IContainerProps extends IBoxProps {
  maxWidth?: string;
  padding?: string;
}

export const Container: React.FC<IContainerProps> = ({ children, ...rest }) => (
  <ContainerWrapper {...rest}>{children}</ContainerWrapper>
);

const ContainerWrapper = styled.div<IContainerProps>`
  max-width: ${p => p.maxWidth || '87.5rem'};
  margin: 0 auto;
  width: ${p => p.width || ''};
  padding: ${p => p.padding || '0 2.5rem'};

  ${p => `
      @media ${p.theme.breakpoints.mobile} {
          padding: 0 1.25rem;
      }
  `}
`;

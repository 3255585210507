import {
  Redirect,
  Route,
  // RouteComponentProps,
  RouteProps,
  Router,
  Switch,
} from 'react-router-dom';
import { routeConfig } from 'config/routeConfig';
import { createBrowserHistory } from 'history';

import { Address } from 'pages/address/Address';
import Home from 'pages/home/Home';
import { MyDrops } from 'pages/mydrops/MyDrops';

export const history = createBrowserHistory();

// const Login = lazyWithRetry(() => import("pages/login/Login"));

type TPrivateRouteType = RouteProps & {
  render: RouteProps['render'];
};

type TRoute = Omit<RouteProps, 'component'> & {
  component?: JSX.Element;
  authorized?: boolean;
};

const routes: Array<TRoute> = [
  {
    path: [routeConfig.home, routeConfig.login.createPassword, routeConfig.login.self],
    render: () => <Home />,
  },
  {
    path: [routeConfig.myDrops.self],
    render: () => <MyDrops />,
  },
  {
    path: [routeConfig.bepAddress.self],
    render: () => <Address />,
  },
];

const PrivateRoute = ({ render, ...rest }: TPrivateRouteType) => (
  <Route {...rest} render={render} />
);
export const browserHistory = createBrowserHistory();

export const Routes = () => (
  <Router history={history}>
    <Switch>
      {routes.map(route => {
        const { path, authorized, component, render } = route;
        if (authorized) {
          return (
            <PrivateRoute
              key={JSON.stringify(path)}
              path={path}
              render={render || (() => component)}
            />
          );
        }
        return (
          <Route
            key={JSON.stringify(path)}
            exact
            path={path}
            render={render || (() => component)}
          />
        );
      })}
      <Redirect to='/p' />
    </Switch>
  </Router>
);

import React from 'react';
import styled from 'styled-components';

import { Container } from 'components/wrappers';
import { Section } from 'components/wrappers/Section';

export interface IModal {
  open: boolean;
  onClose: () => void;
}

export const Modal: React.FC<IModal> = ({ open, onClose, children }) => {
  if (!open) return null;

  return (
    <ModalWrapper>
      <ModalInner>
        <Container>
          <CloseButton onClick={onClose} />
          {children}
        </Container>
      </ModalInner>
    </ModalWrapper>
  );
};

const ModalWrapper = styled(Section)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(14, 15, 16, 0.3);
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.25rem;
  z-index: 99999;
`;

const ModalInner = styled.div`
  max-width: 615px;
  text-align: center;
  padding: 30px 0 40px;
  margin: 0 auto;
  position: relative;
  border: 1px solid hsla(0, 0%, 100%, 0.2);
  background: #0e0f10;
  z-index: 9999;
  width: 100%;
  opacity: 1;
  border-radius: 40px;
`;

const CloseButton = styled.button`
  position: absolute;
  background: none;
  border: none;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
  right: 2rem;
  top: 2.5rem;
  z-index: 3;
  &:before,
  &:after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 1.25rem;
    height: 0.2rem;
    background: #fff;
  }

  &:after {
    transform: rotate(-45deg) translateZ(0);
   }
   &:before {
    transform: rotate(45deg) translateZ(0);
   }
  }
`;

const SvgComponent = () => (
  <svg
    width={20}
    height={14}
    viewBox='0 0 20 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.9438 1.04992C19.2534 1.36792 19.4733 1.76169 19.5813 2.19146C19.8697 3.78407 20.0097 5.39983 19.9995 7.01811C20.0052 8.61221 19.8653 10.2035 19.5813 11.7723C19.4733 12.2021 19.2534 12.5959 18.9438 12.9139C18.6341 13.2319 18.2457 13.4628 17.8178 13.5834C16.2542 14 10 14 10 14C10 14 3.74578 14 2.18222 13.5834C1.76299 13.4692 1.38043 13.2491 1.0715 12.9445C0.762577 12.64 0.537727 12.2612 0.41868 11.8448C0.130302 10.2521 -0.00968046 8.63639 0.00051965 7.01811C-0.00744728 5.41192 0.132526 3.80839 0.41868 2.22768C0.526664 1.79791 0.746586 1.40414 1.05623 1.08614C1.36588 0.768135 1.75429 0.537166 2.18222 0.416559C3.74578 0 10 0 10 0C10 0 16.2542 0 17.8178 0.380336C18.2457 0.500944 18.6341 0.731913 18.9438 1.04992ZM8.38587 3.35688C8.15417 3.21786 7.86561 3.21422 7.63048 3.34735C7.39534 3.48048 7.25 3.7298 7.25 4V10C7.25 10.2702 7.39534 10.5195 7.63048 10.6526C7.86561 10.7858 8.15417 10.7821 8.38587 10.6431L13.3859 7.64312C13.6118 7.50758 13.75 7.26345 13.75 7C13.75 6.73655 13.6118 6.49242 13.3859 6.35688L8.38587 3.35688ZM11.5423 7L8.75 8.67536V5.32464L11.5423 7Z'
      fill='white'
    />
  </svg>
);

export default SvgComponent;

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { routeConfig } from 'config/routeConfig';
import styled from 'styled-components';

import { BodyWrapper, Box, Container, Typography } from 'components';
import { useSearch } from 'hooks/use-search';
import { useUser } from 'hooks/use-user';
import { useValidateTokenMutation } from 'services/api/token';
import { StorageService } from 'services/StorageService';

import { Hero } from './sections/Hero';
import { useAppDispatch } from 'hooks/hooks';
import { setToken } from 'slices/tokenSlice';

export const MyDrops = () => {
  const history = useHistory();
  const { setEmail } = useUser();
  const dispatch = useAppDispatch();
  const query = useSearch();
  const [startValidation, data] = useValidateTokenMutation();
  const urlToken = query.get('token');
  const localToken = StorageService.getAccessToken();

  useEffect(() => {
    if (!urlToken && !localToken) {
      history.push(routeConfig.home);
    }
    const test = async () => {
      try {
        if (urlToken) {
          const token = await startValidation({ token: urlToken }).unwrap();
          setEmail(token.email);
          StorageService.setAccessToken(urlToken);
          dispatch(setToken({ isValid: true }));
          if (!token.token_valid) {
            dispatch(setToken({ isValid: false, errorMessage: token.message }));
            history.push(routeConfig.home);
          }
          return;
        }
        if (localToken) {
          const token = await startValidation({ token: localToken }).unwrap();
          setEmail(token.email);
          dispatch(setToken({ isValid: true }));
          if (!token.token_valid) {
            dispatch(setToken({ isValid: false, errorMessage: token.message }));
            StorageService.clearTokens();
            history.push(routeConfig.home);
          }
          return;
        }
      } catch (error) {
        history.push(routeConfig.home);
      }
    };
    test();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlToken]);

  return (
    <BodyWrapper>
      <Container>
        <Box>
          <Back onClick={() => history.push('/')} type='p2'>
            Back to all airdrops
          </Back>
        </Box>
        {data.data && <Hero {...data.data} airdrops={data.data?.airdrops || []} />}
      </Container>
    </BodyWrapper>
  );
};

const Back = styled(Typography)`
  position: relative;
  margin-left: 1.5rem;
  margin-top: 3.75rem;
  cursor: pointer;
  color: ${p => p.theme.colors.primary};
  transition: opacity 0.25s;
  &:hover {
    opacity: 0.6;
  }
  &:before {
    transition: all 0.25s;
    position: absolute;
    top: 50%;
    left: -1.5rem;
    content: '';
    display: inline-block;
    width: 0.438rem;
    height: 0.438rem;
    border-bottom: 1px solid;
    border-right: 1px solid;
    transform: rotate(134deg);
    margin-left: 0.5rem;
    margin-top: -0.188rem;
  }
`;

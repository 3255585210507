const SvgComponent = () => (
  <svg
    width={18}
    height={16}
    viewBox='0 0 18 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.788 15.8233C14.0295 16.0057 14.3407 16.0513 14.6182 15.9393C14.8957 15.8265 15.0997 15.5737 15.1612 15.2673C15.8129 12.0001 17.3939 3.73052 17.9871 0.758519C18.0321 0.534519 17.9571 0.301719 17.7921 0.152119C17.6271 0.00251863 17.3984 -0.0406815 17.1944 0.0401186C14.0497 1.28172 4.3652 5.15772 0.406789 6.72013C0.155545 6.81933 -0.00795143 7.07693 0.000298384 7.35933C0.00929818 7.64253 0.187794 7.88813 0.445038 7.97053C2.22025 8.53693 4.55044 9.32493 4.55044 9.32493C4.55044 9.32493 5.63942 12.8329 6.20716 14.6169C6.27841 14.8409 6.44265 15.0169 6.6594 15.0777C6.87539 15.1377 7.10639 15.0745 7.26763 14.9121C8.17961 13.9937 9.58958 12.5737 9.58958 12.5737C9.58958 12.5737 12.2685 14.6689 13.788 15.8233V15.8233ZM5.53067 8.88173L6.78989 13.3121L7.06964 10.5065C7.06964 10.5065 11.9348 5.82573 14.7082 3.15772C14.7892 3.07932 14.8005 2.94812 14.733 2.85612C14.6662 2.76412 14.5432 2.74252 14.451 2.80492C11.2365 4.99452 5.53067 8.88173 5.53067 8.88173Z'
      fill='white'
    />
  </svg>
);

export default SvgComponent;

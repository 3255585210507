const SvgComponent = () => (
  <svg
    width={19}
    height={19}
    viewBox='0 0 19 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.615 0c4.206 0 7.615 3.567 7.615 7.967 0 4.4-3.41 7.966-7.615 7.966S0 12.367 0 7.967 3.41 0 7.615 0Zm12.16.467c2.103 0 3.808 3.357 3.808 7.5 0 4.141-1.704 7.5-3.807 7.5-2.103 0-3.808-3.359-3.808-7.5 0-4.142 1.705-7.5 3.808-7.5Zm5.886.78c.74 0 1.339 3.009 1.339 6.72 0 3.71-.6 6.719-1.34 6.719-.739 0-1.338-3.009-1.338-6.72 0-3.71.6-6.718 1.339-6.718Z'
      fill='#fff'
    />
  </svg>
);

export default SvgComponent;

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import React from 'react';
import { Icon } from 'assets/Icon';
import styled from 'styled-components';

import { Box, FlexWrapper, Typography } from 'components';
import { PrimaryButton } from 'components/buttons/PrimaryButton';
import { Image } from 'components/other/Image';
import useHostname from 'hooks/use-pathname';

import { IDrop } from './DropList';

interface IIDropCard extends IDrop {
  setOpenModal: (openModal: boolean) => void;
  setSelected: (item: any) => void;
}

export const DropCard: React.FC<IIDropCard> = ({
  setOpenModal,
  setSelected,
  giveaway,
  paid_at,
  prize_amount,
  bep20_address,
  prize_type,
  wizardia_payment,
  partner,
}) => {
  const hostname = useHostname();
  const handleWinClick = () => {
    setOpenModal(true);
    setSelected({
      prize: prize_amount,
      link: giveaway.link,
      currency: prize_type,
      bem: bep20_address,
      hostedBy: giveaway.hosted_by,
      partner: partner.name,
      giveaway,
      paidAt: paid_at,
      isWizardiaGiveAway: !!wizardia_payment,
      contact: partner.contact,
    });
  };
  return (
    <CardWrapper onClick={handleWinClick}>
      <Image
        padding='0.375rem 0.375rem 0'
        width='100%'
        alt='test'
        src={`${hostname}/${giveaway.image_src}`}
      />
      <Box style={{ display: 'flex', flexDirection: 'column' }} pb={1.25} px={1.25}>
        <TextWrapper width='100%' px={1.25}>
          <StyledLink
            onClick={e => {
              e.stopPropagation();
            }}
            href={giveaway.link}
            target='_blank'
            rel='noreferrer'
          >
            <Title mb={1.25} align='center' type='h4'>
              {giveaway.title}
            </Title>
          </StyledLink>
          <StyledFlexWrapper justifyContent='center' alignItems='center'>
            {!giveaway.ended_at ? <Icon name='clock' /> : null}
            <EndDate
              ml={0.313}
              color={!giveaway.ended_at ? 'darkWhite' : 'primary'}
              width='fit-content'
              align='center'
              type='p2'
            >
              {giveaway.time && giveaway.time}
            </EndDate>
          </StyledFlexWrapper>
        </TextWrapper>
        <ButtonWrapper width='100%'>
          <PrimaryButton
            type='button-regular'
            maxWidth='100%'
            width='100%'
            height='3.75rem'
          >
            {paid_at ? 'View' : 'Claim reward'}
          </PrimaryButton>
        </ButtonWrapper>
      </Box>
    </CardWrapper>
  );
};

const StyledFlexWrapper = styled(FlexWrapper)`
}
`;

const ButtonWrapper = styled(Box)``;

const StyledLink = styled('a')`
  &:hover > p {
    color: ${p => p.theme.colors.primary};
  }
`;
const Title = styled(Typography)`
  font-size: 1.125rem;
  transition: all 0.25s;
`;

const TextWrapper = styled(Box)`
  transition: all 0.25s;
  margin-top: -5.5rem;
  margin-bottom: 2.125rem;
  padding: 0;
  overflow: hidden;
`;

const CardWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    rgba(14, 15, 16, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
  max-width: 17.813rem;
  overflow: hidden;
  backdrop-filter: blur(10px);
  border-radius: 2.5rem;
  transition: border-color 0.25s;
  :hover {
    border-color: ${p => p.theme.colors.primary};
  }
  ${p => `
      @media ${p.theme.breakpoints.tablet} {
          max-width: 100%;
      }
  `}
`;

const EndDate = styled(Typography)`
color: ${p => p.color}
margin-left: 0.375rem;
`;

/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { setGiveawayId } from 'slices/user/userSlice';
// import test from 'assets/images/cardImage.png';
import { useQuery } from 'styles/useQuery';

import { GridWrapper } from 'components';
import { Modal } from 'components/modal/Modal';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { IGiveaway } from 'services/api/airdrops';
import { useValidateTokenMutation } from 'services/api/token';
import { StorageService } from 'services/StorageService';

import { DropCard } from './DropCard';
import { ModalContent } from './ModalContent';

export interface IDrop {
  giveaway: IGiveaway;
  giveaway_id: number;
  id: number;
  text: string;
  bep20_address: string;
  email: string;
  paid_at: string | null;
  prize_amount: string;
  prize_type: string;
  wizardia_payment: number;
  partner: {
    id: number;
    name: string;
    contact: string | null;
  };
}

const getColumns = (isTablet: boolean, isMobile: boolean) => {
  if (isMobile) return 1;
  if (isTablet) return 2;
  return 3;
};

interface IDropList {
  airdrops: Array<IDrop>;
}

export const DropList: React.FC<IDropList> = ({ airdrops }) => {
  const { isTablet, isMobile } = useQuery();
  const [startValidation, _data] = useValidateTokenMutation();
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState<any>();
  const columns = getColumns(isTablet, isMobile);
  const giveawayId = useAppSelector(state => state.user.selectedGiveawayId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const foundDrop = airdrops.find(airdrop => airdrop.giveaway_id === giveawayId);
    if (foundDrop && foundDrop?.bep20_address) {
      setSelected({
        prize: foundDrop?.prize_amount,
        link: foundDrop?.giveaway.link,
        currency: foundDrop?.prize_type,
        bem: foundDrop?.bep20_address,
        hostedBy: foundDrop?.giveaway.hosted_by,
        partner: foundDrop?.partner.name,
        giveaway: foundDrop?.giveaway,
        paidAt: foundDrop?.paid_at,
        isWizardiaGiveAway: !!foundDrop?.wizardia_payment,
        contact: foundDrop?.partner.contact,
      });
      setOpenModal(true);
      dispatch(setGiveawayId({ id: -1 }));
    }
  }, [airdrops, dispatch, giveawayId]);

  if (!airdrops) return null;
  return (
    <>
      <GridWrapper
        gap='1rem'
        width='100%'
        margin='2.5rem auto 7.625rem'
        maxWidth='55.938rem'
        columns={columns}
      >
        {airdrops.map((item: any) => (
          <DropCard
            key={item.giveaway.id}
            setSelected={setSelected}
            {...item}
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
        ))}
      </GridWrapper>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <ModalContent {...selected} />
      </Modal>
    </>
  );
};

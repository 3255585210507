import { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { resetToken } from 'slices/tokenSlice';
import styled from 'styled-components';
import { useQuery } from 'styles/useQuery';

import { Box, Container, Typography } from 'components';
import { PrimaryButton } from 'components/buttons/PrimaryButton';
import { Input, InputTypeEnum } from 'components/inputs/Input';
import { Modal } from 'components/modal/Modal';
import { Section } from 'components/wrappers/Section';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { usePostGiveawaysMutation } from 'services/api/airdrops';
import { useVerifyRecaptchaMutation } from 'services/api/recaptcha';

import { TokenErrorModalContent } from './TokenErrorModalContent/TokenErrorModalContent';

export const Hero = () => {
  const [input, setInput] = useState('');
  const [post, response] = usePostGiveawaysMutation();
  const [postRecaptcha, verifyResponse] = useVerifyRecaptchaMutation();
  const recaptchaRef = useRef(null);
  const { isMobile } = useQuery();
  const [isVerified, setIsVerified] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const token = useAppSelector(state => state.token);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!token.isValid && token.errorMessage) {
      return setOpenErrorModal(true);
    }
    setOpenErrorModal(false);
  }, [token]);

  const handleClick = async () => {
    if (!input) return;
    try {
      await post({ email: input }).unwrap();
    } catch (error) {
      console.error(error);
    } finally {
      if (!recaptchaRef.current) return;
      await (recaptchaRef.current as any).reset();
      setIsVerified(false);
    }
  };
  const onSubmitWithReCAPTCHA = async () => {
    if (!recaptchaRef.current) return;
    const recaptcha: any = recaptchaRef.current;
    const token = await recaptcha.getValue();
    try {
      const data = await postRecaptcha({ 'g-recaptcha-response': token }).unwrap();
      if (data.error) {
        setIsVerified(false);
        await recaptcha.reset();
        return;
      }
      setIsVerified(true);
    } catch (error) {
      await recaptcha.reset();
      setIsVerified(false);
    }
  };

  return (
    <>
      <Section margin={isMobile ? '4.813rem 0 0' : '8.75rem 0 0'}>
        <Container>
          <Typography align='center' color='white' type='h1'>
            Claim reward
          </Typography>
          <Box mt={isMobile ? 1.25 : 1.5} mx='auto' maxWidth='36.625rem'>
            <Typography
              mb={isMobile ? 1.875 : 2.5}
              align='center'
              color='white'
              type='p1'
            >
              Find out if you’re a winner in one of our airdrops. And don’t forget to join
              our latest giveaway!
            </Typography>
            {!response?.data?.email_sent && (
              <Input
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    handleClick();
                  }
                }}
                value={input}
                type={InputTypeEnum.Email}
                label='Enter your e-mail to claim reward'
                name='email'
                onChange={e => setInput(e.target.value)}
              />
            )}
            <StyledForm>
              <ReCAPTCHA
                onChange={onSubmitWithReCAPTCHA}
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA || ''}
              />
            </StyledForm>
            {!response?.data?.email_sent && (
              <Box mb={1} textAlign='center' mt={isMobile ? 1.25 : 2.5}>
                <PrimaryButton
                  loading={response.isLoading}
                  disabled={!input || !isVerified}
                  onClick={handleClick}
                  type='button-regular'
                  height='3.75rem'
                  maxWidth={isMobile ? '100%' : '11.5rem'}
                >
                  Claim reward
                </PrimaryButton>
              </Box>
            )}
            {response.data && (
              <Typography
                align='center'
                mt={1.25}
                type='p2'
                color={response.data.email_sent ? 'primary' : 'red'}
              >
                {response.data.message}
              </Typography>
            )}
          </Box>
        </Container>
      </Section>
      <Modal
        open={openErrorModal}
        onClose={() => {
          setOpenErrorModal(false);
          dispatch(resetToken());
        }}
      >
        <TokenErrorModalContent {...token} />
      </Modal>
    </>
  );
};

const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  margin-top: 1.25rem;
`;

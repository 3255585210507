import { Link } from 'react-router-dom';
import { Icon } from 'assets/Icon';

import Copyright from './Copyright/Copyright';
import FooterColumn from './FooterColumn/FooterColumn';
import FooterDisclaimer from './FooterDisclaimer/FooterDisclaimer';
import { headerLinks } from '../headerUpdated/data';
import { FOOTER_LABELS, FOOTER_LINKS } from './data';
import {
  Divider,
  SFooter,
  SFooterBottom,
  SFooterColumns,
  SFooterInner,
  SFooterOuter,
  SFooterTop,
  SocialIconLink,
  SocialIconsWrapper,
} from './FooterUpdated.styles';

const Footer = () => {
  const socialObj = headerLinks.find(item => item.link_text === 'Community');

  return (
    <SFooter>
      <SFooterOuter>
        <Divider />
        <SFooterTop>
          <Link to='/'>
            <Icon width='9.063rem' height='1.25rem' name='logo' />
          </Link>

          <SocialIconsWrapper>
            {socialObj &&
              socialObj.items &&
              socialObj.items.map(item => (
                <SocialIconLink
                  key={item.id}
                  href={item.url}
                  target='_blank'
                  rel='noreferrer'
                >
                  {item?.image && <Icon name={item.image} />}
                </SocialIconLink>
              ))}
          </SocialIconsWrapper>
        </SFooterTop>

        <SFooterInner>
          <SFooterColumns>
            <FooterColumn
              label={FOOTER_LABELS.menu.game}
              items={FOOTER_LINKS?.footer_game.map(item => ({
                ...item,
                link_text: item.link_text,
              }))}
            />
            <FooterColumn
              label={FOOTER_LABELS.menu.shop}
              items={FOOTER_LINKS?.footer_shop.map(item => ({
                ...item,
                link_text: item.link_text,
              }))}
            />
            <FooterColumn
              label={FOOTER_LABELS.menu.wzrdToken}
              items={FOOTER_LINKS?.footer_token_menu.map(item => ({
                ...item,
                link_text: item.link_text,
              }))}
            />
            <FooterColumn
              label={FOOTER_LABELS.menu.wizardians}
              items={FOOTER_LINKS?.footer_wizardians.map(item => ({
                ...item,
                link_text: item.link_text,
              }))}
            />
            <FooterColumn
              label={FOOTER_LABELS.menu.legal}
              items={FOOTER_LINKS?.footer_legal_menu.map(item => ({
                ...item,
                link_text: item.link_text,
              }))}
            />
          </SFooterColumns>
        </SFooterInner>

        <SFooterBottom>
          <FooterDisclaimer />
          <Copyright />
        </SFooterBottom>
      </SFooterOuter>
    </SFooter>
  );
};

export default Footer;

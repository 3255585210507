import React from 'react';

import { Hero } from './sections/Hero/Hero';
import { Winners } from './sections/Winners/Winners';
import { BodyWrapper } from '../../components';

const Home = () => (
  <BodyWrapper>
    <Hero />
    <Winners />
  </BodyWrapper>
);

Home.displayName = 'Home';

export default Home;

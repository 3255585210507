import { PROD_DOMAIN, STG_DOMAIN } from './domains';

const getApiDomain = () => {
  const { hostname = '' } = window.location;

  if (hostname === PROD_DOMAIN) {
    return process.env.REACT_APP_API_DOMAIN_PROD;
  }

  if (hostname === STG_DOMAIN) {
    return process.env.REACT_APP_API_DOMAIN_STAGING;
  }

  return process.env.REACT_APP_API_DOMAIN;
};

export const API_URLS = {
  DEV: 'http://127.0.0.1:8000',
  STAGING: 'https://staging-giveaways.wizardia.io',
  PRODUCTION: 'https://giveaways.wizardia.io',
};

export const API_DOMAIN = getApiDomain();

import { Icon } from 'assets/Icon';
import styled from 'styled-components/macro';

import { Typography } from 'components/Typography';

export const StyledHeader = styled.header`
  padding: 0.625rem 0;
  max-width: 1200px;
  color: white;
  margin: 0 auto;
  @media screen and (min-width: 1024px) {
    padding: 30px 0;
  }
`;

export const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background: linear-gradient(0deg, rgba(36, 31, 31, 0) 0, 30%, #141313 80%);
  transition: background-color 0.2s ease-out;
  &._light {
    background: white;
    div {
      &:after {
        border-color: black;
      }
      &:hover::after {
        border-color: #7aebe4;
      }
      span {
        color: black;
      }
    }
    a {
      span {
        color: black;
      }
      &:hover {
        span {
          color: #7aebe4;
        }
      }
    }
    button:not([open]) {
      &:before,
      &:after {
        background: black;
      }
    }
  }
`;

export const HeaderPlayGameText = styled(Typography)`
  font-size: 14px;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  color: white;

  &._light {
    color: black;
  }
`;

export const HeadwayMobileContainer = styled.div.attrs({
  className: 'headway-container-mobile',
})`
  display: flex;
  align-items: center;
`;

export const HeadwayContainer = styled.div.attrs({
  className: 'headway-container',
})`
  display: flex;
  align-items: center;
`;
export const Container = styled.div`
  overflow: visible;
  padding: 0 1.25rem;
  margin: 0 auto;
  width: 100%;
`;

export const NavButton = styled.a`
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-align: center;
  background: rgba(122, 235, 228, 0.063);
  color: rgb(255, 255, 255);
  border-radius: 40px;
  border: 1px solid rgb(122, 235, 228);
  box-shadow: rgb(122 235 228 / 50%) 0px 0px 20px inset;
  backdrop-filter: blur(10px);
  line-height: 14px;
  font-weight: 700;
  transition: all 0.2s ease 0s;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  padding: 13px 16px;
  &:hover {
    background: linear-gradient(-65deg, #7aebe410, #7aebe480);
  }
  @media screen and (min-width: 480px) {
    padding: 13px 32px;
  }

  @media screen and (min-width: 1024px) {
    padding: 13px 16px;
  }

  @media (min-width: 1125px) {
    padding: 13px 32px;
  }
`;

export const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  min-height: 42px;
  width: 100%;
`;

export const LinksWrapper = styled.div`
  display: none;
  @media screen and (min-width: 1024px) {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
`;

export const ButtonWrapper = styled.div`
  display: none;
  @media screen and (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 30px;
  }
`;

export const MobileMenuWrapper = styled.div`
  display: flex;
  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

export const BurgerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 11px;
`;

export const StyledButton = styled.button<{ open: boolean }>`
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 99999;
  :before {
    display: block;
    content: '';
    transform: ${p => (p.open ? 'rotate(-45deg) translate(0.438rem)' : '')};
    width: 1.563rem;
    height: 0.125rem;
    margin-bottom: 0.375rem;
    background: white;
    transform-origin: right;
    transition: transform 0.3s ease-out;
  }
  :after {
    display: block;
    content: '';
    width: 1.563rem;
    height: 2px;
    transform: ${p => (p.open ? 'rotate(45deg) translate(0.5rem)' : '')};
    background: white;
    transform-origin: right;
    transition: transform 0.3s ease-out;
  }
`;
export const StyledIcon = styled(Icon)`
  z-index: 100;
`;

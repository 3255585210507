const SvgComponent = () => (
  <svg width={16} height={16} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx={8} cy={8} r={7} stroke='#7AEBE4' strokeWidth={2} />
    <path
      d='M8 5.2v3.15l2.1 2.1'
      stroke='#7AEBE4'
      strokeWidth={2}
      strokeLinecap='round'
    />
  </svg>
);

export default SvgComponent;

export const capitalizeFirstOnly = (word: string) => {
  if (!word) return '';
  const [first, ...rest] = word.split('');
  return first.toUpperCase() + rest.join('');
};

export const daysDifference = (date: string) => {
  const now = new Date();
  const then = new Date(date);
  const diff = Math.abs(now.getTime() - then.getTime());
  return Math.ceil(diff / (1000 * 3600 * 24));
};

// format date to 'DD/MM/YYYY'
export const formatDate = (date: string) => {
  const d = new Date(date);
  const month = `0${d.getMonth() + 1}`.slice(-2);
  const day = `0${d.getDate()}`.slice(-2);
  const year = `${d.getFullYear()}`;
  return `${month}/${day}/${year}`;
};

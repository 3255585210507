const SvgComponent = () => (
  <svg
    width={19}
    height={19}
    viewBox='0 0 19 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.325.388a.755.755 0 0 0-1.32 0L.072 9.336c-.229.46.115.976.631.976h3.328v3.383c0 .345.286.632.63.632h1.95a.636.636 0 0 0 .63-.63v-3.385h-.916a.746.746 0 0 1-.746-.631c0-.115 0-.23.057-.345l2.753-4.99L6.325.388ZM10.677.388a.755.755 0 0 1 1.318 0l4.933 8.948c.23.46-.115.976-.631.976h-3.269v3.383a.637.637 0 0 1-.632.632h-2.064a.637.637 0 0 1-.631-.63v-3.385h.918a.746.746 0 0 0 .745-.631c0-.115 0-.23-.057-.345l-2.753-4.99L10.677.389Z'
      fill='#fff'
    />
  </svg>
);

export default SvgComponent;

import { useEffect, useState } from 'react';

export const useLoaded = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (document.readyState === 'complete') {
      handleLoad();
    } else {
      window.addEventListener('load', handleLoad);
    }

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  const handleLoad = () => {
    setLoaded(true);
  };

  return loaded;
};

const SvgComponent = () => (
  <svg
    width={19}
    height={19}
    viewBox='0 0 19 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M17.619 0H2.381A2.384 2.384 0 0 0 0 2.381v15.238A2.384 2.384 0 0 0 2.381 20h15.238A2.384 2.384 0 0 0 20 17.619V2.381A2.384 2.384 0 0 0 17.619 0Zm-1.902 8.725a3.567 3.567 0 0 1-3.314-1.597v5.494a4.06 4.06 0 1 1-4.06-4.06c.085 0 .167.008.25.013v2c-.083-.01-.164-.024-.25-.024a2.072 2.072 0 1 0 0 4.144c1.145 0 2.156-.902 2.156-2.046l.02-9.33h1.914A3.565 3.565 0 0 0 15.719 6.5v2.224'
      fill='#fff'
    />
  </svg>
);

export default SvgComponent;

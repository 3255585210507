import React from 'react';
import styled from 'styled-components';

import { Box, IBoxProps } from 'components/Box';

interface IProps extends IBoxProps {
  justifyContent?: 'center' | 'space-between' | 'flex-start' | 'flex-end';
  direction?: 'column' | 'row' | 'column-reverse' | 'row-reverse';
  alignItems?:
    | 'center'
    | 'space-between'
    | 'flex-start'
    | 'flex-end'
    | 'normal'
    | 'strech'
    | 'baseline';
  width?: string;
  className?: string;
  wrap?: string;
  height?: string;
  minHeight?: string;
  overflow?: string;
  rowGap?: string;
  columnGap?: string;
  as?: keyof JSX.IntrinsicElements;
}

export const FlexWrapper: React.FC<IProps> = ({
  as,
  children,
  className,
  justifyContent,
  alignItems,
  direction,
  wrap,
  height,
  minHeight,
  overflow,
  columnGap,
  rowGap,
  ...boxProps
}) => {
  return (
    <StyledBox
      justifyContent={justifyContent}
      direction={direction}
      alignItems={alignItems}
      className={className}
      wrap={wrap}
      height={height}
      minHeight={minHeight}
      overflow={overflow}
      columnGap={columnGap}
      rowGap={rowGap}
      boxAs={as}
      {...boxProps}
    >
      {children}
    </StyledBox>
  );
};

const StyledBox = styled(Box)<IProps>`
  display: flex;
  justify-content: ${p => p.justifyContent || 'flex-start'};
  flex-direction: ${p => p.direction};
  flex-wrap: ${p => p.wrap};
  align-items: ${p => p.alignItems};
  max-width: ${p => p.width};
  height: ${p => p.height};
  min-height: ${p => p.minHeight};
  overflow: ${p => p.overflow};
  column-gap: ${p => p.columnGap};
  row-gap: ${p => p.rowGap};
`;

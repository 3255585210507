import React from 'react';
import styled from 'styled-components';
import { theme } from 'styles/theme';

import { images } from 'helpers';

interface IStyles {
  width?: string;
  height?: string;
  padding?: string;
  margin?: string;
  maxHeight?: string;
  maxWidth?: string;
}

export type IconsType = keyof typeof images;

interface IProps extends IStyles {
  src: string;
  alt: string;
  mobileSrc?: IconsType;
  tabletSrc?: IconsType;
  className?: string;
  id?: string;
}

const Img = styled.img<IStyles>`
  display: block;
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || 'auto'};
  padding: ${({ padding }) => padding || 0};
  margin: ${({ margin }) => margin || 0};
  max-height: ${({ maxHeight }) => maxHeight || 'none'};
  max-width: ${({ maxWidth }) => maxWidth || 'unset'};
`;

export const Image: React.FC<IProps> = ({
  src,
  alt,
  mobileSrc,
  tabletSrc,
  className,
  id,
  ...rest
}) =>
  src ? (
    <picture className={className}>
      {tabletSrc && images[tabletSrc] ? (
        <source media={theme.breakpoints.tablet} srcSet={images[tabletSrc]} />
      ) : null}
      {mobileSrc && images[mobileSrc] ? (
        <source media={theme.breakpoints.mobile} srcSet={images[mobileSrc]} />
      ) : null}
      <Img draggable='false' id={id} src={src} alt={alt} {...rest} />
    </picture>
  ) : null;

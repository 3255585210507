import styled from 'styled-components';

interface IProps {
  columns: number;
  rows?: number;
  gap?: string;
  width?: string;
  maxWidth?: string;
  margin?: string;
  display?: string;
  alignSelf?: string;
  position?: string;
  alignItems?: string;
}

export const GridWrapper = styled.div<IProps>`
  display: ${({ display }) => display || 'grid'};
  grid-template-columns: ${props => `repeat(${props.columns}, minmax(0, 1fr))`};
  grid-template-rows: ${props => (props.rows ? props.rows : 'auto')};
  grid-gap: ${props => (props.gap ? props.gap : '0 0')};
  width: ${props => props.width || '100%'};
  max-width: ${props => props.maxWidth || '100%'};
  align-self: ${props => props.alignSelf || 'auto'};
  margin: ${props => props.margin || '0'};
  position: ${props => props.position || 'static'};
  align-items: ${props => props.alignItems || 'stretch '};
`;

import { PayloadAction } from '@reduxjs/toolkit';

import { IUserState } from './userSlice';

const reducer = {
  setEmail(state: IUserState, { payload }: PayloadAction<{ email: string }>) {
    return {
      ...state,
      email: payload.email,
    };
  },
  setGiveawayId(state: IUserState, { payload }: PayloadAction<{ id: number }>) {
    return {
      ...state,
      selectedGiveawayId: payload.id,
    };
  },
};

export { reducer };

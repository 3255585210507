import styled from 'styled-components/macro';

export const SCopyright = styled.div`
  display: flex;
  padding: 30px 0 0;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (min-width: 768px) {
    padding: 20px 0 0;
  }
`;

export const SCopyrightLinksText = styled.p`
  font-size: 12px;
  line-height: 22px;
  font-weight: 400;
  color: white;
  &:hover {
    color: #7aebe4;
  }
  @media screen and (min-width: 768px) {
    font-size: 14px;
  }
`;

export const SCopyrightText = styled(SCopyrightLinksText)`
  margin: 0 0 20px 0;
  &:hover {
    color: white;
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  margin: 0 0 20px 0;
`;

export const SCopyrightLink = styled.a`
  text-decoration: none;
`;

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';

import { theme } from './styles/theme';
import { App } from './App';
import { store } from './store';

import './index.css';

export const persistor = persistStore(store);

const root = document.getElementById('root');
const data = root?.getAttribute('data-props');
const props = JSON.parse(data || '{ }');

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<div>loading placeholder</div>} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <App props={props} />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

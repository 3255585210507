import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITokenInterface } from 'types/services/token';

const initialState = {} as ITokenInterface;

const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setToken(_, action: PayloadAction<ITokenInterface>) {
      return action.payload;
    },
    resetToken(_) {
      return initialState;
    },
  },
});

export const { setToken, resetToken } = tokenSlice.actions;
export default tokenSlice.reducer;
